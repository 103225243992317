import { useEffect, useState } from 'react';

import {
    Button,
    SpaceBetween,
    Modal,
    Box,
    ColumnLayout,
    FormField,
    Input,
  } from '@cloudscape-design/components';

export default function BackupModal({ site, visible, onDiscard, onBackup }) {
  const [note, setNote] = useState('');
  useEffect(() => {
    setNote("");
  }, [visible]);

  const handleBackupSubmit = event => {
    event.preventDefault();
    onBackup(note);
  };

  if (!visible) {
    return null;
  }

  return (
    <Modal
      visible={visible}
      onDismiss={onDiscard}
      header="Backup site"
      closeAriaLabel="Close dialog"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onDiscard}>Cancel</Button>
            <Button variant="primary" onClick={handleBackupSubmit}>Backup</Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="m">
        <Box variant="span">
          Backup site{' '}
          <Box variant="span" fontWeight="bold">
            {site.name}
          </Box>{' '}
        </Box>

        <ColumnLayout columns={1}>
          <form onSubmit={handleBackupSubmit}>
            <FormField label="Backup notes (optional)">
              <Input
                // placeholder={site.name}
                onChange={event => setNote(event.detail.value)}
                value={note}
                ariaRequired={true}
                ariaLabelledby="backup-confirmation-label"
                autoComplete={false}
                disableBrowserAutocorrect={true}
              />
            </FormField>
          </form>
        </ColumnLayout>
      </SpaceBetween>
    </Modal>
  );
}
