import { useEffect, useState } from 'react';
import { loggedIn } from "../components/common";
// import { NewSiteWizard } from "../components/new-site-wizard";
import { subVersionsBreadcrumbs } from "../components/common";

import { useNavigate } from 'react-router-dom';
import { NewVersionForm } from "../components/new-version-form";

import {
  BreadcrumbGroup,
} from '@cloudscape-design/components';

import '../css/base.scss';

const Breadcrumbs = ({navigate, item}) => (
  <BreadcrumbGroup
    onFollow={event => {
      event.preventDefault();
      navigate(event.detail.href);
    }}
    items={subVersionsBreadcrumbs(item)}
    expandAriaLabel="Show path"
    ariaLabel="Breadcrumbs" />
)

export default function NewVersion({ state }) {
  const navigate = useNavigate();
  if (!loggedIn()) {
    return null;
  }
  useEffect(() => {
    state.activeHref.setHref("/versions");
    state.breadCrumbs.setCrumbs(
      <Breadcrumbs navigate={navigate} item="Create Version" />
    )
    state.maxContentWidth.setWidth(Number.MAX_VALUE);
    state.contentType.setType("default");
  }, []);
  return (
      <NewVersionForm resource="Version" url="versions" flashbar={state.flashBar}/>
  );
}
