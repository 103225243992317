/*
 * Learn more about Cloudscape Design System at
 * https://cloudscape.design
 */

// import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "@cloudscape-design/components/side-navigation";
import { useAuthenticator } from '@aws-amplify/ui-react';

export default function SideNavigation(props) {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();
  // const navigate = useNavigate();
  // cant get this setActiveHref to work...
  // const [activeHref, setActiveHref] = useState(
  //   "#/dashboard"
  // );
  // MyTopNavigation();

  // const [currentHref, setCurrentHref] = useState(document.location.hash);
  // if (currentHref.includes("#/sites/")) {
  //   setCurrentHref("#/" + currentHref.split("/")[1]);
  // }
  // console.log("SideNavigation: activeHref: ", props.activeHref);
  return (
    <Navigation
      activeHref={props.activeHref}
      header={{ id: "use-router", href: "/", text: "Home" }}
      onFollow={event => {
        if (event.detail.text === "Login") {
          props.state.loginModal.setVisible(true);
        }
        if (event.detail.text === "Logout") {
          signOut();
          // navigate("/landing");
        }
        // This is needed to pass links through React Router.
        // Add `id: "use-router"` to all links that should use React Router.
        if (event.detail.id === "use-router") {
          event.preventDefault();
          navigate(event.detail.href);
        }
      }}
      items={ user ? [
        // { type: "link", text: "test", href: "#1" },
        { type: "link", id: "use-router", text: "Dashboard", href: "/dashboard" },
        { type: "link", id: "use-router", text: "Sites", href: "/sites" },
        { type: "link", id: "use-router", text: "Products", href: "/products" },
        { type: "link", id: "use-router", text: "Versions", href: "/versions" },
        { type: "link", id: "use-router", text: "Regions", href: "/regions" },
        { type: "link", id: "use-router", text: "Stacks", href: "/stacks" },
        { type: "link", id: "use-router", text: "Databases", href: "/databases" },
        { type: "link", id: "use-router", text: "Load Balancers", href: "/loadbalancers" },
        { type: "link", id: "use-router", text: "Storage", href: "/storage" },
        { type: "link", id: "use-router", text: "S3 Buckets", href: "/s3buckets" },
        { type: "link", id: "use-router", text: "Cache Stores", href: "/cachestores" },
        { type: "link", id: "use-router", text: "Events", href: "/events" },
        { type: "divider" },
        { type: "link", text: "Logout", href: "#" },
        // {
        //   type: "link",
        //   text: "Notifications",
        //   href: "#/notifications",
        //   info: <Badge color="red">23</Badge>
        // },
        // {
        //   type: "link",
        //   text: "Documentation",
        //   href: "https://docs.example.com",
        //   external: true
        // }
      ] : [
        {
          type: "link",
          text: "Login",
          href: "#"
        }
      ]
    }
    />
  );
}
