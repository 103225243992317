import { useMemo } from 'react';
import axios from "axios";
import { Auth } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate, useLocation } from 'react-router-dom';
import ShowNotifications from './notifications';

import {
    Link,
  } from '@cloudscape-design/components';

// export function CounterLink(props) {
//     return (
//         <Link variant="awsui-value-large" href={props.link}>
//             {props.children}
//         </Link>
//     );
// }



// Breadcrumb start
export const sitesBreadcrumbs = [
    {
        text: 'Sites',
        href: '/sites',
    },
]

export const siteDetailsBreadcrumbs = (site) => [
    ...sitesBreadcrumbs,
    {
        text: site,
        href: '#',
    }
]

export const productsBreadcrumbs = [
    {
        text: 'Products',
        href: '/products',
    },
]

export const subProductsBreadcrumbs = (product) => [
    ...productsBreadcrumbs,
    {
        text: product,
        href: '#',
    }
]

export const versionsBreadcrumbs = [
    {
        text: 'Versions',
        href: '/versions',
    },
]

export const subVersionsBreadcrumbs = (version) => [
    ...versionsBreadcrumbs,
    {
        text: version,
        href: '#',
    }
]

export const regionsBreadcrumbs = [
    {
        text: 'Regions',
        href: '/regions',
    },
]

export const subRegionsBreadcrumbs = (region) => [
    ...regionsBreadcrumbs,
    {
        text: region,
        href: '#',
    }
]

export const stacksBreadcrumbs = [
    {
        text: 'Stacks',
        href: '/stacks',
    },
]

export const subStacksBreadcrumbs = (stack) => [
    ...stacksBreadcrumbs,
    {
        text: stack,
        href: '#',
    }
]

export const databasesBreadcrumbs = [
    {
        text: 'Databases',
        href: '/databases',
    },
]

export const subDatabasesBreadcrumbs = (database) => [
    ...databasesBreadcrumbs,
    {
        text: database,
        href: '#',
    }
]

export const loadbalancersBreadcrumbs = [
    {
        text: 'Loadbalancers',
        href: '/loadbalancers',
    },
]

export const subLoadbalancersBreadcrumbs = (loadbalancer) => [
    ...loadbalancersBreadcrumbs,
    {
        text: loadbalancer,
        href: '#',
    }
]

export const storageBreadcrumbs = [
    {
        text: 'Storage',
        href: '/storage',
    },
]

export const subStorageBreadcrumbs = (storage) => [
    ...storageBreadcrumbs,
    {
        text: storage,
        href: '#',
    }
]

export const s3bucketsBreadcrumbs = [
    {
        text: 'S3 Buckets',
        href: '/s3buckets',
    },
]

export const subS3bucketsBreadcrumbs = (s3bucket) => [
    ...s3bucketsBreadcrumbs,
    {
        text: s3bucket,
        href: '#',
    }
]

export const cachestoresBreadcrumbs = [
    {
        text: 'Cache Stores',
        href: '/cachestores',
    },
]

export const subCachestoresBreadcrumbs = (cachestore) => [
    ...cachestoresBreadcrumbs,
    {
        text: cachestore,
        href: '#',
    }
]

// Breadcrumb end

export const CounterLink = ({ link, children }) => {
  const navigate = useNavigate();
  return (
    <Link variant="awsui-value-large" href={link} onFollow={event => { event.preventDefault(); navigate(event.detail.href); }}>
      {children}
    </Link>
  );
};


export async function axiosGet(url) {
  const getJWT = Auth.currentAuthenticatedUser().then(res=>{
    // console.log(`jwt1: ${res.signInUserSession.idToken.jwtToken}`);
    return res.signInUserSession.idToken.jwtToken
  })

  return await getJWT
  .then(jwt => axios.get(process.env.REACT_APP_API_URL + url, { headers: { 'Authorization': 'Bearer ' + jwt, 'Content-Type': 'application/json' } }))
  .then((response) => {
    // console.log(response)
    return response
  });
}

export async function axiosPost(url, data) {
  const getJWT = Auth.currentAuthenticatedUser().then(res=>{
    // console.log(`jwt1: ${res.signInUserSession.idToken.jwtToken}`);
    return res.signInUserSession.idToken.jwtToken
  })

  return await getJWT
  .then(jwt => axios.post(process.env.REACT_APP_API_URL + url, data, { headers: { 'Authorization': 'Bearer ' + jwt, 'Content-Type': 'application/json' } }))
  .then((response) => {
    // console.log(response)
    return response
  });
}

export async function axiosDelete(url) {
  const getJWT = Auth.currentAuthenticatedUser().then(res=>{
    // console.log(`jwt1: ${res.signInUserSession.idToken.jwtToken}`);
    return res.signInUserSession.idToken.jwtToken
  })

  return await getJWT
  .then(jwt => axios.delete(process.env.REACT_APP_API_URL + url, { headers: { 'Authorization': 'Bearer ' + jwt, 'Content-Type': 'application/json' } }))
  .then((response) => {
    // console.log(response)
    return response
  });
}

export async function axiosPut(url, data) {
  const getJWT = Auth.currentAuthenticatedUser().then(res=>{
    // console.log(`jwt1: ${res.signInUserSession.idToken.jwtToken}`);
    return res.signInUserSession.idToken.jwtToken
  })

  return await getJWT
  .then(jwt => axios.put(process.env.REACT_APP_API_URL + url, data, { headers: { 'Authorization': 'Bearer ' + jwt, 'Content-Type': 'application/json' } }))
  .then((response) => {
    // console.log(response)
    return response
  });
}

export function deleteResource(item, resource, url, flashbar, setReload) {
  if (item?.name === undefined) {
    item.name = item.id
  }
  axiosDelete(url + "/" + item.id)
  .then((response) => {
    if (response.data.error !== false) {
      ShowNotifications({
        child: url,
        flashbar: flashbar,
        // callback: setReload,
        header: `Error deleting ${resource} ${item.name}:`,
        message: response?.data?.result || "Unknown error",
        type: "error"
      });
    } else {
      ShowNotifications({
        child: url,
        flashbar: flashbar,
        // callback: setReload,
        header: `Deleted ${resource}:`,
        message: item.name
      });
      setReload(true);
    }
  })
  .catch((error) => {
    ShowNotifications({
      child: url,
      flashbar: flashbar,
      // callback: setReload,
      header: `Error deleting ${resource} ${item.name}:`,
      message: error.response?.data?.result || error.message,
      type: "error"
    });
  });
}

export function putSite(item, operation, data, flashbar, callback=null) {
  axiosPut("sites/" + item.id + "/" + operation, data)
  .then((response) => {
    if (response.data.error !== false) {
      ShowNotifications({
        child: "sites",
        flashbar: flashbar,
        // callback: setReload,
        header: `Error ${operation} for ${item.name}:`,
        message: response?.data?.result || "Unknown error",
        type: "error"
      });
    } else {
      ShowNotifications({
        child: "sites",
        flashbar: flashbar,
        // callback: setReload,
        header: `${operation} pending for site:`,
        message: item.name
      });
      if (typeof callback === "function") {
        callback();
      }
    }
  })
  .catch((error) => {
    ShowNotifications({
      child: "sites",
      flashbar: flashbar,
      // callback: setReload,
      header: `Error: ${operation} for ${item.name}:`,
      message: error.response?.data?.result || error.message,
      type: "error"
    });
  });
}

export function putVersion(item, data, flashbar, callback=null) {
  axiosPut("versions/" + item.id, data)
  .then((response) => {
    if (response.data.error !== false) {
      ShowNotifications({
        child: "versions",
        flashbar: flashbar,
        // callback: setReload,
        header: `Error updating version ${item.name}:`,
        message: response?.data?.result || "Unknown error",
        type: "error"
      });
    } else {
      ShowNotifications({
        child: "versions",
        flashbar: flashbar,
        // callback: setReload,
        header: `Update pending for version:`,
        message: item.name
      });
      if (typeof callback === "function") {
        callback();
      }
    }
  })
  .catch((error) => {
    ShowNotifications({
      child: "versions",
      flashbar: flashbar,
      // callback: setReload,
      header: `Error updating version ${item.name}:`,
      message: error.response?.data?.result || error.message,
      type: "error"
    });
  });
}

export async function loggedIn() {
  const { authStatus } = await useAuthenticator(context => [context.authStatus]);
  // console.log(`authStatus: ${authStatus}`);
  // const { route } = useAuthenticator((context) => [context.route]);
  // const [ loggedIn, setLoggedIn ] = React.useState(false);
  // console.log(`authStatus: ${authStatus}`);
  // React.useEffect(() => {
  //   if (route === 'authenticated') {
  //     setLoggedIn(true);
  //   }
  // }, [route]);
  // return loggedIn;
  // console.log(`authStatus: ${authStatus}`);
  return authStatus === 'authenticated';
}

export const capitalizeFirst = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export function getResource({name, resource, setResource}) {
  setResource({ ...resource, status: "loading" });
  axiosGet(name)
  .then((response) => {
    if (response.data.error !== false) {
      console.log(response.data.error);
    } else {
      var options = response.data.result.map(e => { return { label: e.name, value: e.id } });
      var update = { options: options, status: "finished" }
      setResource({ ...resource, ...update, status: "finished" });
    }
  })
  .catch((error) => {
    // console.log(error);
    setResource({ ...resource, status: "error" });
  });
}
