import { useState, useEffect } from 'react';useState
import { TableContent, checkSelectedItems } from './table';
import DeleteModal from './delete-modal';
import BackupModal from './site-backup-modal';
import RestoreModal from './site-restore-modal';
import { putSite, deleteResource } from './common';
import {
  Button,
  ButtonDropdown,
  SpaceBetween,
} from '@cloudscape-design/components';
import { capitalizeFirst } from './common';

const tableResource = "Backups";

const columnDefinitions = navigate => [
  { id: "id", header: "ID", cell: (e) => e.id, sortingField: "id" },
  { id: "epoch", header: "Date", cell: (e) => {
    if (e.epoch === null) {
      return "Pending";
    }
    return new Date(e.epoch * 1000).toLocaleString('en-US', {"dateStyle": "medium", "timeStyle": "long"});
  }, sortingField: "epoch" },
  // { id: "epoch", header: "Epoch", cell: (e) => e.epoch, sortingField: "epoch" },
  { id: "dbtype", header: "DB Type", cell: (e) => e.dbtype, sortingField: "dbtype" },
  { id: "notes", header: "Notes", cell: (e) => e.notes !== "" ? e.notes : "-", sortingField: "notes" },
  { id: "s3bucket", header: "S3 Bucket", cell: (e) => e.s3bucket, sortingField: "s3bucket" },
  { id: "status", header: "Status", cell: (e) => e.status, sortingField: "status" },
  { id: "version", header: "Version", cell: (e) => e.version, sortingField: "version" },
  { id: "versionid", header: "Version ID", cell: (e) => e.versionid, sortingField: "versionid" },
]

const visibleContentItems = [
  { id: "id", label: "ID" },
  { id: "epoch", label: "Epoch", editable: false },
  { id: "dbtype", label: "DB Type" },
  { id: "notes", label: "Notes" },
  { id: "s3bucket", label: "S3 Bucket" },
  { id: "status", label: "Status", editable: false },
  { id: "version", label: "Version", editable: false },
  { id: "versionid", label: "Version ID" },
]

const visibleContent = [
  'epoch',
  'dbtype',
  'notes',
  's3bucket',
  'status',
  'version',
]

const tableButtons = (props) => {
  /*
    Props available are:
    selectedItems: array of selected items
    loading: boolean - true if table is loading
    setReloadData: function to reload data. use SetReloadData(true)
    navigate: function to navigate to a page. passed to useNavigate()
    flashbar: functions to set flashbar items.
  */
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const onDeleteInit = () => setShowDeleteModal(true);
  const onDeleteDiscard = () => setShowDeleteModal(false);
  const onDeleteConfirm = () => {
    const deleted = props.selectedItems;
    props.setSelectedItems([]);
    setShowDeleteModal(false);
    deleted.forEach((item) => {
      deleteResource(
        item,
        "Backup",
        "backups",
        props.flashbar,
        () => { props.setReloadData(true); props.data.setReload(true); },
      );
    });
    props.setReloadData(true);
  };

  const [showBackupModal, setShowBackupModal] = useState(false);
  const onBackupInit = () => setShowBackupModal(true);
  const onBackupDiscard = () => setShowBackupModal(false);
  const onBackupConfirm = (backupNote) => {
    setShowBackupModal(false);
    putSite(
      props.data.siteData,
      "backup",
      { notes: backupNote },
      props.flashbar,
    );
    // reload both the General Configuration and Backups panels.
    setTimeout(() => { props.setReloadData(true); props.data.setReload(true); }, 1500)
  };

  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const onRestoreInit = () => setShowRestoreModal(true);
  const onRestoreDiscard = () => setShowRestoreModal(false);
  const onRestoreConfirm = () => {
    setShowRestoreModal(false);
    const restored = props.selectedItems;
    putSite(
      props.data.siteData,
      "restore",
      { id: restored[0].id },
      props.flashbar,
    );
    // reload both the General Configuration and Restores panels.
    setTimeout(() => { props.setReloadData(true); props.data.setReload(true); }, 1500)
  };

  return (
    <SpaceBetween size="xs" direction="horizontal">
      <DeleteModal
        visible={showDeleteModal}
        onDiscard={onDeleteDiscard}
        onDelete={onDeleteConfirm}
        items={props.selectedItems}
        type="backup"
      />
      <BackupModal
        visible={showBackupModal}
        onDiscard={onBackupDiscard}
        onBackup={onBackupConfirm}
        site={props.data.siteData}
      />
      <RestoreModal
        visible={showRestoreModal}
        onDiscard={onRestoreDiscard}
        onRestore={onRestoreConfirm}
        items={props.selectedItems}
      />
      <Button
        loading={props.loading}
        iconName="refresh"
        onClick={() => { props.setReloadData(true) }}
      />
      <ButtonDropdown
        expandToViewport
        disabled={props.selectedItems.length === 0}
        items={[
          {
            text: "",
            items: [
              { text: "Restore this backup", id: "restore", disabled: (props.selectedItems.length !== 1 || props.selectedItems[0].status !== "complete"), disabledReason: "You can only restore a single completed backup.", },
              { text: "Clone to new site", id: "clone", disabled: (props.selectedItems.length !== 1 || props.selectedItems[0].status !== "complete"), disabledReason: "You can only clone a single completed backup.", },
            ]
          },
          {
            text: "",
            items: [
              {
                text: "Delete",
                id: "delete",
                disabled: (props.selectedItems.length === 0 || !checkSelectedItems(props.selectedItems, "status", "complete")),
                disabledReason: "You can only delete completed backups.",
              },
            ]
          }
        ]}
        onItemClick={event => {
          if (event.detail.id === "delete") {
            onDeleteInit(true);
          } else if (event.detail.id === "restore") {
            onRestoreInit(true);
          } else if (event.detail.id === "clone") {
            console.log("clone backup id: ", props.selectedItems[0].id);
          }
        }}
      >
        Actions
      </ButtonDropdown>
      <Button
        variant="primary"
        onClick={() => { onBackupInit(true) }}
      >
        Create {capitalizeFirst(tableResource.slice(0, -1))}
      </Button><br />
    </SpaceBetween>
  );
}

export default function SiteBackups({flashBar, siteId, data}) {
  return (
    <TableContent
    resource={tableResource}
    selectionType="multi"
    stickyHeader={true}
    variant="embedded"
    api={{
      url: "backups/" + siteId + "?pretty=1",
      method: "get",
    }}
    columnDefinitions={columnDefinitions}
    visibleContent={visibleContent}
    visibleContentItems={visibleContentItems}
    buttons={tableButtons}
    buttonprops={{"data": data}}
    flashbar={flashBar}
    filterType="properties"
    />
  )
}
