// import * as React from "react";
import { useEffect, useState } from 'react';
import '@aws-amplify/ui-react/styles.css';
import { ContentLayout } from "@cloudscape-design/components";

// page specific imports.
import Grid from '@cloudscape-design/components/grid';

import "../css/dashboard.scss";

import { loggedIn } from '../components/common';
import { DashboardHeader } from "../components/header";
import ServiceOverview from "../components/service-overview";
import ServiceHealth from "../components/service-health";
// import ZoneStatus from './components/zone-status';
// import Alarms from './components/alarms';
// import InstancesLimits from './components/instance-limits';
// import Events from './components/events';
// import CPUUtilisation from './components/cpu-utilisation';
// import NetworkTraffic from './components/network-traffic';
import { axiosGet } from '../components/common';



export function loadData({setData}) {
  axiosGet("dashboard")
  .then((response) => {
    if (response.data.error !== false) {
      let result = {
        name: "Error",
        error: response.data.error,
      }
      setData(result);
      return null;
    }else {
      // console.log(response);
      if (response.data.result.length === 0) {
        let result = {
          name: "Error",
          error: "No site found with that id",
        }
        setData(result);
        return null;
      }
      setData(response.data.result[0]);
    }
  })
  .catch((error) => {
    // console.log(error);
    let result = {
      name: "Error",
      error: error.message,
    }
    setData(result);
    return null;
  });
}



function Content(props) {
  const [ data, setData ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(null);

  useEffect(() => {
    loadData({setData});
  }, []);

  return (
    <Grid
      gridDefinition={[
        { colspan: { l: 9, m: 9, default: 12 } },
        { colspan: { l: 3, m: 3, default: 12 } },
        // { colspan: { l: 8, m: 8, default: 12 } },
        // { colspan: { l: 4, m: 4, default: 12 } },
        // { colspan: { l: 4, m: 4, default: 12 } },
        // { colspan: { l: 6, m: 6, default: 12 } },
        // { colspan: { l: 6, m: 6, default: 12 } },
        // { colspan: { l: 6, m: 6, default: 12 } },
        // { colspan: { l: 6, m: 6, default: 12 } },
        // { colspan: { l: 6, m: 6, default: 12 } },
        // { colspan: { l: 6, m: 6, default: 12 } },
        // { colspan: { l: 8, m: 8, default: 12 } },
        // { colspan: { l: 4, m: 4, default: 12 } },
      ]}
    >
      <ServiceOverview data={data}/>
      <ServiceHealth />
      {/* <ServiceOverview data={data}/>
      <ServiceHealth /> */}
      {/*
      <ServiceHealth updateTools={props.updateTools} />
      <CPUUtilisation />
      <NetworkTraffic />
      <Alarms />
      <InstancesLimits />
      <Events />
      <ZoneStatus />
      <FeaturesSpotlight />
      <AccountAttributes />
      */}
    </Grid>
  );
}


export default function Dashboard({ state }) {
  if (!loggedIn()) {
    return null;
  }
  useEffect(() => {
    state.activeHref.setHref("/dashboard");
    state.breadCrumbs.setCrumbs(null);
    state.maxContentWidth.setWidth(Number.MAX_VALUE);
    state.contentType.setType("default");
  }, []);
  return (
    <ContentLayout header={<DashboardHeader/>}>
      <Content/>
    </ContentLayout>
  );
}
