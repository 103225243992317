// import React from 'react';
import {
  Textarea,
} from "@cloudscape-design/components";

export default function SiteLogs({site}) {
  return (
    <Textarea
      id="site-logs"
      label="Site Logs"
      value={site}
      // defaultValue="Site Logs"
      readOnly
    />
  )
}
