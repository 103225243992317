import { useEffect, useState } from 'react';
import ShowNotifications from '../components/notifications';
import { putSite } from '../components/common';

import {
  ContentLayout,
  Icon,
  Link,
  Button,
  ButtonDropdown,
  SpaceBetween,
  StatusIndicator,
} from '@cloudscape-design/components';

import { SitesHeader } from "../components/header";
import { loggedIn, capitalizeFirst } from "../components/common";
import RenameModal from "../components/site-rename-modal";
import DeleteModal from "../components/site-delete-modal";
import SuspendModal from "../components/site-suspend-modal";
import UpgradeModal from '../components/site-upgrade-modal';
import BackupModal from '../components/site-backup-modal';
import TemplateModal from '../components/site-template-modal';
import sitelogin from '../components/sitelogin';
import { TableContent, checkSelectedItems } from "../components/table";

const tableResource = "sites";

const columnDefinitions = navigate => [
  { id: "id", header: "ID", cell: (e) => e.id, sortingField: "id" },
  { id: "name", header: "Name", cell: (e) => (<Link href={"/sites/" + e.id} onFollow={event => { event.preventDefault(); navigate(event.detail.href); }} >{e.name}</Link>), sortingField: "name" },
  { id: "stackid", header: "Stack ID", cell: (e) => e.stackid, sortingField: "stackid" },
  { id: "dbhostid", header: "DB Host ID", cell: (e) => e.dbhostid, sortingField: "dbhostid" },
  { id: "lbhostid", header: "LB Host ID", cell: (e) => e.lbhostid, sortingField: "lbhostid" },
  { id: "cachehostid", header: "Cache Host ID", cell: (e) => e.cachehostid, sortingField: "cachehostid" },
  { id: "datarootid", header: "Data Root ID", cell: (e) => e.datarootid, sortingField: "datarootid" },
  { id: "s3bucketid", header: "S3 Bucket ID", cell: (e) => e.s3bucketid, sortingField: "s3bucketid" },
  { id: "targetversionid", header: "Target Version ID", cell: (e) => e.targetversionid, sortingField: "targetversionid" },
  { id: "currentversionid", header: "Current Version ID", cell: (e) => e.currentversionid, sortingField: "currentversionid" },
  { id: "status", header: "Status", sortingField: "status", cell: (e) =>
    (e.status === "active") ? (
      <StatusIndicator type="success">{e.status}</StatusIndicator>
    ) : (e.status === "pending") ? (
      <StatusIndicator type="pending">{e.status}</StatusIndicator>
    ) : (e.status === "suspended") ? (
      <StatusIndicator type="stopped">{e.status}</StatusIndicator>
    ) : (
      <StatusIndicator type="error">{e.status}</StatusIndicator>
    )
  },
  { id: "state", header: "State", sortingField: "state", cell: (e) => 
    (e.state !== null) ? (
      <StatusIndicator type="in-progress">{e.state}</StatusIndicator>
    ) : (
      "-"
    )
  },
  { id: "stack", header: "Stack", cell: (e) => e.stack, sortingField: "stack" },
  { id: "dbhost", header: "DB Host", cell: (e) => e.dbhost, sortingField: "dbhost" },
  { id: "lbhost", header: "LB Host", cell: (e) => e.lbhost, sortingField: "lbhost" },
  { id: "cachehost", header: "Cache Host", cell: (e) => e.cachehost, sortingField: "cachehost" },
  { id: "dataroot", header: "Dataroot", cell: (e) => e.dataroot, sortingField: "dataroot" },
  { id: "s3bucket", header: "S3 Bucket", cell: (e) => e.s3bucket, sortingField: "s3bucket" },
  { id: "currentversion", header: "Current Version", cell: (e) => e.currentversion, sortingField: "currentversion" },
  { id: "targetversion", header: "Target Version", cell: (e) => e.targetversion, sortingField: "targetversion" },
  { id: "template", header: "Template", cell: (e) => e.template ? <Icon name="status-positive" /> : "-", sortingField: "template" },
]

const visibleContentItems = [
  { id: "id", label: "ID" },
  { id: "name", label: "Name", editable: false },
  // { id: "stackid", label: "Stack ID" },
  // { id: "dbhostid", label: "DB Host ID" },
  // { id: "lbhostid", label: "LB Host ID" },
  // { id: "datarootid", label: "Dataroot ID" },
  // { id: "s3bucketid", label: "S3 Bucket ID" },
  { id: "status", label: "Status" },
  // { id: "targetversionid", label: "Target Version ID" },
  // { id: "currentversionid", label: "Current Version ID" },
  { id: "state", label: "State" },
  { id: "template", label: "Template" },
  { id: "stack", label: "Stack" },
  { id: "dbhost", label: "DB Host" },
  { id: "lbhost", label: "LB Host" },
  { id: "cachehost", label: "Cache Host" },
  { id: "dataroot", label: "Dataroot" },
  { id: "s3bucket", label: "S3 Bucket" },
  { id: "currentversion", label: "Current Version" },
  { id: "targetversion", label: "Target Version" },
]

const visibleContent = [
  'name',
  // 'stack',
  'status',
  'state',
  'currentversion',
  'targetversion',
]

// const tableButtons = (selectedItems, loading, reloadData, navigate) => {
const tableButtons = (props) => {
  /*
    Props available are:
    selectedItems: array of selected items
    loading: boolean - true if table is loading
    setReloadData: function to reload data. use SetReloadData(true)
    navigate: function to navigate to a page. passed to useNavigate()
    flashbar: functions to set flashbar items.
  */

  const [reload, setReload] = useState(false);
  const [ actionloading, setActionLoading ] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const onDeleteInit = () => setShowDeleteModal(true);
  const onDeleteDiscard = () => setShowDeleteModal(false);
  const onDeleteConfirm = () => {
    const deleted = props.selectedItems;
    props.setSelectedItems([]);
    console.log("Will delete: ", props.selectedItems);
    setShowDeleteModal(false);
    ShowNotifications({
      child: "sites",
      flashbar: props.flashbar,
      header: `Requested deletion of ${deleted.length} site${deleted.length > 1 ? 's' : ''}:`,
      message: deleted.map(it => it.name).join(', '),
    });
    props.setReloadData(true);
  };

  const [showRenameModal, setShowRenameModal] = useState(false);
  const onRenameInit = () => setShowRenameModal(true);
  const onRenameDiscard = () => setShowRenameModal(false);
  const onRenameConfirm = (newName) => {
    const renamed = props.selectedItems;
    props.setSelectedItems([]);
    setShowRenameModal(false);
    putSite(
      renamed[0],
      "rename",
      { name: newName },
      props.flashbar,
      setTimeout(() => props.setReloadData(true), 1000)
    )
  };

  const [showSuspendModal, setShowSuspendModal] = useState(false);
  const onSuspendInit = () => setShowSuspendModal(true);
  const onSuspendDiscard = () => setShowSuspendModal(false);
  const onSuspendConfirm = () => {
    const Suspended = props.selectedItems;
    const suspendAction = (Suspended[0].status === "active") ? "suspend" : "resume";
    props.setSelectedItems([]);
    setShowSuspendModal(false);
    Suspended.forEach(site => {
      // TODO: turn this into a batch request / sleep between requests
      putSite(
        site,
        suspendAction,
        undefined,
        props.flashbar,
        (site === Suspended[Suspended.length - 1]) ? setTimeout(() => props.setReloadData(true), 1500) : null,
      );
    });
  };

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const onUpgradeInit = () => setShowUpgradeModal(true);
  const onUpgradeDiscard = () => setShowUpgradeModal(false);
  const onUpgradeConfirm = (newVersion) => {
    const upgraded = props.selectedItems[0];
    props.setSelectedItems([]);
    setShowUpgradeModal(false);
    console.log("Will upgrade: ", upgraded);
    console.log("To version: ", newVersion);
    putSite(
      upgraded,
      "upgrade",
      { targetversionid: newVersion.value },
      props.flashbar,
    );
    setTimeout(() => props.setReloadData(true), 1500)
  };

  const [showBackupModal, setShowBackupModal] = useState(false);
  const onBackupInit = () => setShowBackupModal(true);
  const onBackupDiscard = () => setShowBackupModal(false);
  const onBackupConfirm = (backupNote) => {
    const backupsite = props.selectedItems[0];
    props.setSelectedItems([]);
    setShowBackupModal(false);
    // console.log("Will backup: ", backupsite);
    // console.log("To version: ", backupNote);
    putSite(
      backupsite,
      "backup",
      { notes: backupNote },
      props.flashbar,
    );
    setTimeout(() => props.setReloadData(true), 1500)
  };

  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const onTemplateInit = () => setShowTemplateModal(true);
  const onTemplateDiscard = () => setShowTemplateModal(false);
  const onTemplateConfirm = () => {
    const templated = props.selectedItems[0];
    props.setSelectedItems([]);
    setShowTemplateModal(false);
    // console.log("Will template: ", !templated.template);
    putSite(
      templated,
      "istemplate",
      { template: !templated.template },
      props.flashbar,
    );
    setTimeout(() => props.setReloadData(true), 1500);
  };

  const [showSnapshotTemplateModal, setShowSnapshotTemplateModal] = useState(false);
  const onSnapshotTemplateInit = () => setShowSnapshotTemplateModal(true);
  const onSnapshotTemplateDiscard = () => setShowSnapshotTemplateModal(false);
  const onSnapshotTemplateConfirm = () => {
    const snaptemplate = props.selectedItems[0];
    props.setSelectedItems([]);
    setShowSnapshotTemplateModal(false);
    // console.log("Will template: ", snaptemplate);
    putSite(
      snaptemplate,
      "template",
      undefined,
      props.flashbar,
    );
    setTimeout(() => props.setReloadData(true), 1500);
  };

  useEffect(() => {
    if (reload) {
      props.setReloadData(true);
      setReload(false);
    }
  }, [reload]);

  return (
    <SpaceBetween size="xs" direction="horizontal">
      <DeleteModal
        visible={showDeleteModal}
        onDiscard={onDeleteDiscard}
        onDelete={onDeleteConfirm}
        sites={props.selectedItems}
      />
      <RenameModal
        visible={showRenameModal}
        onDiscard={onRenameDiscard}
        onRename={onRenameConfirm}
        site={props.selectedItems?.[0]}
      />
      <SuspendModal
        visible={showSuspendModal}
        onDiscard={onSuspendDiscard}
        onSubmit={onSuspendConfirm}
        sites={props.selectedItems}
        action={props.selectedItems?.[0]?.status === "suspended" ? "Unsuspend" : "Suspend"}
      />
      <UpgradeModal
        visible={showUpgradeModal}
        onDiscard={onUpgradeDiscard}
        onUpgrade={onUpgradeConfirm}
        site={props.selectedItems?.[0]}
      />
      <BackupModal
        visible={showBackupModal}
        onDiscard={onBackupDiscard}
        onBackup={onBackupConfirm}
        site={props.selectedItems?.[0]}
      />
      <TemplateModal
        visible={showTemplateModal}
        onDiscard={onTemplateDiscard}
        onSubmit={onTemplateConfirm}
        sites={props.selectedItems}
        action={props.selectedItems?.[0]?.template === true ? "Unset as template" : "Set as template"}
      />
      <TemplateModal
        visible={showSnapshotTemplateModal}
        onDiscard={onSnapshotTemplateDiscard}
        onSubmit={onSnapshotTemplateConfirm}
        sites={props.selectedItems}
        action="Snapshot template"
      />
      <Button
        loading={props.loading}
        iconName="refresh"
        onClick={() => { props.setReloadData(true) }}
      />
      <Button
        disabled={props.selectedItems.length !== 1}
        onClick={() => {
          props.navigate(`/${tableResource}/${props.selectedItems[0].id}`);
        }}
      >
        View Details
      </Button>
      <ButtonDropdown
        expandToViewport
        disabled={props.selectedItems.length === 0}
        loading={actionloading}
        items={[
          {
            text: "",
            items: [
              {
                text: "Login as admin",
                id: "login",
                href: (props.selectedItems[0] ? `https://${props.selectedItems[0].name}` : "#"),
                external: true,
                disabled: props.selectedItems.length !== 1,
                disabledReason: "Available on a single site only",
              },
            ]
          },
          {
            text: "",
            items: [
              { text: "Rename", id: "rename", disabled: (props.selectedItems.length !== 1 || props.selectedItems[0].status !== "active"), disabledReason: "You can only rename a single active site.", },
              { text: "Suspend", id: "suspend", disabled: (props.selectedItems.length === 0 || !checkSelectedItems(props.selectedItems, "status", "active")), disabledReason: "You can only suspend active sites.", },
              { text: "Unsuspend", id: "unsuspend", disabled: (props.selectedItems.length === 0 || !checkSelectedItems(props.selectedItems, "status", "suspended")), disabledReason: "You can only unsuspend suspended sites.", },
              { text: "Upgrade", id: "upgrade", disabled: (props.selectedItems.length !== 1 || props.selectedItems[0].status !== "active"), disabledReason: "You can only upgrade a single active site.", },
              { text: "Backup", id: "backup", disabled: (props.selectedItems.length !== 1 || props.selectedItems[0].status !== "active"), disabledReason: "You can only backup a single active site.", },
            ]
          },
          {
            text: "",
            items: [
              {
                text: "Set as template",
                id: "template",
                disabled: (props.selectedItems.length !== 1 || props.selectedItems[0].status !== "active" || props.selectedItems[0].template === true),
                disabledReason: "You can only set a single active non-template site as a template.",
              },
              {
                text: "Unset as template",
                id: "untemplate",
                disabled: (props.selectedItems.length !== 1 || props.selectedItems[0].status !== "active" || props.selectedItems[0].template === false),
                disabledReason: "You can only unset a single active template site.",
              },
              {
                text: "Snapshot template",
                id: "snapshotTemplate",
                disabled: (props.selectedItems.length !== 1 || props.selectedItems[0].status !== "active" || props.selectedItems[0].template === false),
                disabledReason: "You can only snapshot a single active template site.",
              }
            ]
          },
          {
            text: "",
            items: [
              // { text: "Delete", id: "delete", disabled: (props.selectedItems.length === 0 || props.selectedItems[0].status !== "active") }, // TODO fix this, check ALL instances.
              {
                text: "Delete",
                id: "delete",
                disabled: (props.selectedItems.length === 0 || !checkSelectedItems(props.selectedItems, "status", "active")),
                disabledReason: "You can only delete active sites.",
              },
            ]
          }
        ]}
        onItemClick={event => {
          if (event.detail.id === "login") {
            event.preventDefault();
            // const noop = () => { };
            sitelogin(event.detail.href, setActionLoading, props.flashbar);
          } else if (event.detail.id === "rename") {
            onRenameInit(true);
          } else if (event.detail.id === "suspend" || event.detail.id === "unsuspend") {
            onSuspendInit(true);
          } else if (event.detail.id === "upgrade") {
            onUpgradeInit(true);
          } else if (event.detail.id === "delete") {
            onDeleteInit(true);
          } else if (event.detail.id === "backup") {
            onBackupInit(true);
          } else if (event.detail.id === "template" || event.detail.id === "untemplate") {
            onTemplateInit(true);
          } else if (event.detail.id === "snapshotTemplate") {
            onSnapshotTemplateInit(true);
          } else {
            console.log("Unknown action", event.detail.id);
          }
        }}
      >
        Actions
      </ButtonDropdown>
      <Button
        variant="primary"
        onClick={() => {
            props.navigate(`/${tableResource}/new`);
        }}
      >
        Create {capitalizeFirst(tableResource.slice(0, -1))}
      </Button>
    </SpaceBetween>
  );
}

export default function Sites({ state }) {
  if (!loggedIn()) {
    return null;
  }
  useEffect(() => {
    state.activeHref.setHref("/sites");
    state.breadCrumbs.setCrumbs(null);
    state.maxContentWidth.setWidth(Number.MAX_VALUE);
    state.contentType.setType("default");
  }, []);
  return (
    <ContentLayout header={<SitesHeader/>}>
      <TableContent
        resource={tableResource}
        selectionType="multi"
        stickyHeader={true}
        api={{
          url: "sites?pretty=1",
          method: "get",
          // payload: null,
        }}
        columnDefinitions={columnDefinitions}
        visibleContent={visibleContent}
        visibleContentItems={visibleContentItems}
        buttons={tableButtons}
        flashbar={state.flashBar}
      />
    </ContentLayout>
  );
}
