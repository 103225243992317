// import React from 'react';
import { Container, Header, StatusIndicator, SpaceBetween } from '@cloudscape-design/components';
// import { InfoLink } from './common';

export default function ServiceHealth(props) {
  return (
    <Container
    //   variant="stacked"
      header={
        <Header
          variant="h2"
        //   info={
        //     <InfoLink
        //       onFollow={() => props.updateTools(<ServiceHealthInfo />)}
        //       ariaLabel={'Information about service health.'}
        //     />
        //   }
        >
          Service health
          {/* - <em>new</em> */}
        </Header>
      }
    >
      {/* <ColumnLayout columns="1">
        <div> */}
        {/* <div style={{ display: 'grid', minHeight: 105 }}> */}
        <div>
            <SpaceBetween direction="vertical" size="s">
                {/* <Box variant="awsui-key-label">Services</Box> */}
                <StatusIndicator type="success">Cloud API</StatusIndicator>
                <StatusIndicator type="success">Terraform API</StatusIndicator>
                <StatusIndicator type="success">Website</StatusIndicator>
                {/* <br /> */}
            </SpaceBetween>
        </div>
        {/* </div> */}
        {/* <div>
          <Box variant="awsui-key-label">Status</Box>
          <StatusIndicator type="success">Service is operating normally</StatusIndicator>
        </div> */}
        {/* </ColumnLayout> */}
    </Container>
  );
}

// export default function ServiceHealth(props) {
//     return (
//       <Container
//         header={
//           <Header
//             variant="h2"
//             // info={
//             //   <InfoLink
//             //     onFollow={() => props.updateTools(<ServiceHealthInfo />)}
//             //     ariaLabel={'Information about service health.'}
//             //   />
//             // }
//           >
//             Service health - <em>new</em>
//           </Header>
//         }
//       >
//         <ColumnLayout columns="2">
//           <div>
//             <Box variant="awsui-key-label">Region</Box>
//             <div>US East (N. Virginia)</div>
//           </div>
//           <div>
//             <Box variant="awsui-key-label">Status</Box>
//             <StatusIndicator type="success">Service is operating normally</StatusIndicator>
//           </div>
//         </ColumnLayout>
//       </Container>
//     );
//   }
