import { useReducer } from 'react';

function reducer(state, action) {
  switch (action.type) {
    case 'add':
      return {
        ...state,
        [action.payload.child]: [
          ...state[action.payload.child],
          action.payload.item
        ]
      };
    case 'remove':
      return {
        ...state,
        [action.payload.child]: state[action.payload.child].filter(item => item.id !== action.payload.item.id)
      };
    default:
      throw new Error();
  }
}

export default function useNotifications() {
  const initialState = {
    sites: [],
    products: [],
    versions: [],
    regions: [],
    stacks: [],
    databases: [],
    cachestores: [],
    loadbalancers: [],
    storage: [],
    s3buckets: [],
  };
  const [notifications, dispatch] = useReducer(reducer, initialState);
  return [ notifications, dispatch ];
}
