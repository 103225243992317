// import React from 'react';
import { useState, useCallback, useEffect, useMemo, memo } from 'react';
import {
  SpaceBetween,
  ExpandableSection,
  AttributeEditor,
  Input,
  Spinner,
  FormField,
  Multiselect,
  Button,
  ButtonDropdown,
  TokenGroup,
  Box,
  StatusIndicator,
  Select,
} from "@cloudscape-design/components";
import { getResource, axiosGet, axiosPost } from "./common";

// function getPlugins({ siteData, setPlugins }) {
//   var branch = null;
//   if (siteData?.currentversion == null) { return; }
//   axiosGet("versions?" + "id=" + siteData.currentversion)
//     .then((response) => {
//       if (response.data.error !== false) {
//         console.log(response.data.error);
//       } else {
//         return response.data.result[0].branch;
//       }
//     })
//     .then((branch) => {
//       branch = "BUILDS/workdemo/MOODLE-3.11.11/template.workdemo.ethinksites.com_2022121400";
//       axiosPost("versions/plugins", { branch: branch })
//         .then((response) => {
//           if (response.data.error !== false) {
//             console.log(response.data.error);
//           } else {
//             setPlugins(response.data.result.blacklist);
//           }
//         })
//       })
// }

function getPlugins({ siteData, plugins, setPlugins }) {
  if (siteData?.currentversion == null) { return; }
  setPlugins({ ...plugins, status: "loading" });
  // var branch = null;
  axiosGet("versions?" + "id=" + siteData.currentversion)
  .then((response) => {
    if (response.data.error !== false) {
      console.log(response.data.error);
    } else {
      return response.data.result[0].branch;
    }
  })
  .then((branch) => {
    branch = "BUILDS/workdemo/MOODLE-3.11.11/template.workdemo.ethinksites.com_2022121400";
    axiosPost("versions/plugins", { branch: branch })
    .then((response) => {
      if (response.data.error !== false) {
        console.log(response.data.error);
      } else {
        // setPlugins(response.data.result.blacklist);
        var options = response.data.result.blacklist.map((plugin) => {
          return { label: plugin, value: plugin };
        });
        var update = { options: options, status: "finished" }
        setPlugins({ ...plugins, ...update, status: "finished" });
      }
    })
    .catch((error) => {
      setPlugins({ ...plugins, status: "error" });
    });
  })
  .catch((error) => {
    setPlugins({ ...plugins, status: "error" });
  });
}

const handleSubmit = event => {
  event.preventDefault();
  console.log("submit: ", plugins);
}

const ConfigControl = memo(({ value, index, placeholder, setConfig, prop }) => {
  return (
    <Input
      value={value}
      placeholder={placeholder}
      onChange={({ detail }) => {
        setConfig(config => {
          const updatedItems = [...config];
          updatedItems[index] = {
            ...updatedItems[index],
            [prop]: detail.value,
          };
          return updatedItems;
        });
      }}
    />
  );
});

export default function SiteConfig({siteData}) {
  const [tokenAlignment, setTokenAlignment] = useState('horizontal');
  const defaultPluginsState = { value: [], selected: undefined, options: [], status: "pending", error: false, required: true };
  const [plugins, setPlugins] = useState(defaultPluginsState);
  const [config, setConfig] = useState([
    { key: 'reportbuilder/pathtochrome', value: '""'},
    { key: 'showservicesandsupportcontent', value: true},
  ]);

  useEffect (() => {
    // getPlugins({ siteData, plugins, setPlugins });
    // generate dummy data to fill plugins list with 500 items in the form of { label: "plugin name", value: "plugin name" }
    var options = [];
    var selected = [];
    for (var i = 0; i < 500; i++) {
      let option = { label: "plugin" + i, value: "plugin" + i }
      options.push(option);
      // add 50 items to selection
      if (i < 50) {
        selected.push(option);
      }
    }
    setPlugins({ ...plugins, selected: selected, options: options, status: "finished" });
  }, [siteData]);

  const configdefinition = useMemo(
    () => [
      {
        label: 'Config Key',
        control: ({ key = '' }, itemIndex) => (
          <ConfigControl prop="key" value={key} index={itemIndex} placeholder="Enter key" setConfig={setConfig} />
        ),
        errorText: item => (item.key && item.key.match(/^AWS/i) ? 'Key cannot start with "AWS"' : null),
      },
      {
        label: 'Value',
        control: ({ value = '' }, itemIndex) => (
          <ConfigControl prop="value" value={value} index={itemIndex} placeholder="Enter value" setConfig={setConfig} />
        ),
        errorText: item =>
          item.value && item.value.length > 10 ? (
            <span>
              Value {item.value} is longer than 10 characters, <Link variant="info">Info</Link>
            </span>
          ) : null,
      },
      {
        label: 'Type',
        control: ({ type = 'string' }, itemIndex) => (
          <Select
            value={type}
            onChange={({ detail }) => {
              setConfig(config => {
                const updatedItems = [...config];
                updatedItems[itemIndex] = {
                  ...updatedItems[itemIndex],
                  type: detail.value,
                };
                return updatedItems;
              });
            }}
            options={[
              { label: 'string', value: 'string' },
              { label: 'boolean', value: 'bool' },
              { label: 'number', value: 'int'},
            ]}
          />
        ),
            
      }
    ],
    []
  );

  const onConfigAddButtonClick = useCallback(() => {
    setConfig(config => [...config, {}]);
  }, []);

  const onConfigRemoveButtonClick = useCallback(({ detail: { itemIndex } }) => {
    setConfig(config => {
      const newConfig = config.slice();
      newConfig.splice(itemIndex, 1);
      return newConfig;
    });
  }, []);

  return (
    <SpaceBetween size="xl">
      <ExpandableSection
        defaultExpanded
        headerText="Enabled Plugins"
      >
        { plugins == null && <Spinner /> ||
          <SpaceBetween size="xxxs">
            <SpaceBetween
              size="m"
              direction="horizontal"
            >
              <Multiselect
                placeholder="Select plugins to enable"
                selectedOptions={plugins.selected}
                onChange={({ detail }) => {
                  setPlugins({ ...plugins, selected: detail.selectedOptions });
                }}
                options={plugins.options}
                loadingText="Loading plugins"
                statusType={plugins.status}
                empty="No plugins available for this build"
                errorText="Error fetching plugins"
                recoveryText="Retry"
                selectedAriaLabel="Selected"
                filteringType="auto"
                filteringPlaceholder="Search plugins"
                virtualScroll={ plugins.options.length > 100 }
                hideTokens
              />
              { plugins.selected && plugins.selected.length > 0 &&
                <ButtonDropdown
                  items={[
                    { text: "Horizontal", id: 'horizontal' },
                    { text: "Vertical", id: 'vertical' },
                  ]}
                  onItemClick={({ detail }) => setTokenAlignment(detail.id) }
                >
                  Display: {tokenAlignment}
                </ButtonDropdown>
              }
              <Button
              >
                Save
              </Button>
              <Box
                margin="xxxs"
                padding="xxs"
              >
                <StatusIndicator type="warning">
                  You have unsaved changes
                </StatusIndicator>
              </Box>
            </SpaceBetween>
            <Box float="left">
              <TokenGroup
                items={plugins.selected}
                alignment={tokenAlignment}
                limit={10}
                onDismiss={({ detail: { itemIndex } }) => {
                  setPlugins({ ...plugins, selected: plugins.selected.filter((_, index) => index !== itemIndex) });
                }}
                i18nStrings={{
                  limitShowFewer: "Show fewer plugins",
                  limitShowMore: "Show more plugins"
                }}
              />
            </Box>
          </SpaceBetween>
        }
      </ExpandableSection>
      <ExpandableSection
        defaultExpanded
        headerText="Site Settings"
      >
        <AttributeEditor
          items={config}
          definition={configdefinition}
          onAddButtonClick={onConfigAddButtonClick}
          onRemoveButtonClick={onConfigRemoveButtonClick}
          addButtonText="Add Config"
          removeButtonText="Remove"
          empty="No additional config settings"
        />
      </ExpandableSection>
    </SpaceBetween>
  );
}
