// import React from 'react';
import {
  SpaceBetween,
  Header,
  Button,
  ButtonDropdown,
} from '@cloudscape-design/components';
// import { Button, Header } from '@cloudscape-design/components';
// import { InfoLink } from '../../commons/common-components';

export function LoginHeader(props) {
  return (
    <SpaceBetween direction="vertical" size="xxs">
    <br />
    <Header variant="h2">
      This page requires authentication.
    </Header>
    </SpaceBetween>
  );
}


export function DashboardHeader(props) {
  return (
    <SpaceBetween direction="vertical" size="xxs">
    <br />
    <Header
      variant="h1"
    //   info={
    //     <InfoLink onFollow={() => props.updateTools(<EC2Info />)} ariaLabel={'Information about service dashboard.'} />
    //   }
    //   actions={<Button variant="primary">Launch instance</Button>}
    >
      Service Dashboard
    </Header>
    </SpaceBetween>
  );
}

export function SitesHeader(props) {
  return (
    <SpaceBetween direction="vertical" size="xxs">
    <br />
    <Header
      variant="h1"
    //   info={
    //     <InfoLink onFollow={() => props.updateTools(<EC2Info />)} ariaLabel={'Information about service dashboard.'} />
    //   }
    //   actions={<Button variant="primary">Launch instance</Button>}
    >
      Sites List
    </Header>
    </SpaceBetween>
  );
}

// export function SiteDetailsHeader(props) {
export const SiteDetailsHeader = ({ buttons, site }) => {
  return (
    <Header
      variant="h1"
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          {buttons.map((button, key) =>
            !button.items ? (
              <Button
                href={button.href || ''}
                disabled={button.disabled || false}
                key={key}
                onClick={button?.onClick}
                onFollow={button?.onFollow}
                iconName={button?.iconName}
                loading={button?.loading}
                target={button?.target}
              >
                {button.text}
              </Button>
            ) : (
              <ButtonDropdown items={button.items} key={key} onItemClick={button?.onItemClick} onFollow={button?.onFollow} external={button?.external}>
                {button.text}
              </ButtonDropdown>
            )
          )}
        </SpaceBetween>
      }
    >
      {site}
    </Header>
  );
}

export function ProductsHeader() {
  return (
    <SpaceBetween direction="vertical" size="xxs">
      <br />
      <Header variant="h1">Products List</Header>
    </SpaceBetween>
  );
}

export function VersionsHeader() {
  return (
    <SpaceBetween direction="vertical" size="xxs">
      <br />
      <Header variant="h1">Versions List</Header>
    </SpaceBetween>
  );
}

export function RegionsHeader() {
  return (
    <SpaceBetween direction="vertical" size="xxs">
      <br />
      <Header variant="h1">Regions List</Header>
    </SpaceBetween>
  );
}

export function StacksHeader() {
  return (
    <SpaceBetween direction="vertical" size="xxs">
      <br />
      <Header variant="h1">Stacks List</Header>
    </SpaceBetween>
  );
}

export function DatabasesHeader() {
  return (
    <SpaceBetween direction="vertical" size="xxs">
      <br />
      <Header variant="h1">Databases List</Header>
    </SpaceBetween>
  );
}

export function StorageHeader() {
  return (
    <SpaceBetween direction="vertical" size="xxs">
      <br />
      <Header variant="h1">Storage List</Header>
    </SpaceBetween>
  );
}

export function S3bucketsHeader() {
  return (
    <SpaceBetween direction="vertical" size="xxs">
      <br />
      <Header variant="h1">S3 Buckets List</Header>
    </SpaceBetween>
  );
}

export function EventsHeader() {
  return (
    <SpaceBetween direction="vertical" size="xxs">
      <br />
      <Header variant="h1">Event Log</Header>
    </SpaceBetween>
  );
}

export function LoadbalancersHeader() {
  return (
    <SpaceBetween direction="vertical" size="xxs">
      <br />
      <Header variant="h1">Load Balancers List</Header>
    </SpaceBetween>
  );
}

export function CachestoresHeader() {
  return (
    <SpaceBetween direction="vertical" size="xxs">
      <br />
      <Header variant="h1">Cache Stores List</Header>
    </SpaceBetween>
  );
}

export function NewResourceHeader({resource}) {
  return (
    <SpaceBetween direction="vertical" size="xxs">
      <Header variant="h1">Create {resource}</Header>
    </SpaceBetween>
  );
}
