import { useEffect, useState } from 'react';

import {
    Button,
    SpaceBetween,
    Modal,
    Box,
    Alert,
    ColumnLayout,
    FormField,
    Input,
  } from '@cloudscape-design/components';

export default function RenameModal({ site, visible, onDiscard, onRename }) {
  if (site === undefined) {
    var site = { name: "" };
  }
  const [siteName, setSiteName] = useState('');
  useEffect(() => {
    setSiteName("");
  }, [visible]);

  const handleRenameSubmit = event => {
    event.preventDefault();
    if (siteName.toLowerCase() === site.name.toLowerCase() 
      || siteName === ""
      || siteName === undefined
      || siteName === null
      || siteName.length < 5
      || siteName.length > 53
      || !siteName.match(/^[a-z0-9-.]+$/)
    ) {
      onDiscard();
    }else {
      onRename(siteName);
    }
  };

  return (
    <Modal
      visible={visible}
      onDismiss={onDiscard}
      header="Rename site"
      closeAriaLabel="Close dialog"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onDiscard}>Cancel</Button>
            <Button variant="primary" onClick={handleRenameSubmit}
              disabled={
                siteName === '' ||
                siteName.toLowerCase() === site.name.toLowerCase() ||
                siteName.length > 53 ||
                siteName.length < 5 ||
                !siteName.match(/^[a-z0-9-.]+$/)
              }
            >Rename</Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="m">
        <Box variant="span">
          Rename site{' '}
          <Box variant="span" fontWeight="bold">
            {site.name}
          </Box>{' '}
          permanently? This action cannot be undone.
        </Box>

        <Alert type="warning">
          Proceeding with this action will rename the site with all content and can impact related resources.{' '}
        </Alert>

        <ColumnLayout columns={1}>
          <form onSubmit={handleRenameSubmit}>
            <FormField label="New site name" constraintText="must be between 5 and 53 characters, and can only contain lowercase letters and numbers.">
              <Input
                placeholder={site.name}
                onChange={event => setSiteName(event.detail.value)}
                value={siteName}
                ariaRequired={true}
                ariaLabelledby="rename-confirmation-label"
                autoComplete={false}
                disableBrowserAutocorrect={true}
              />
            </FormField>
          </form>
        </ColumnLayout>
      </SpaceBetween>
    </Modal>
  );
}
