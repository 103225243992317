import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import { Authenticator } from '@aws-amplify/ui-react';
// import Amplify from "aws-amplify";
import Amplify from '@aws-amplify/core';
// import Auth from '@aws-amplify/auth';
import awsExports from "./aws-exports";
Amplify.configure(awsExports);

import "@cloudscape-design/global-styles/index.css";

import App from "./App";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
    <StrictMode>
      <Authenticator.Provider>
          <App />
      </Authenticator.Provider>
    </StrictMode>
);
