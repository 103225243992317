import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
// import { Login } from './pages/Login';
// export function RequireAuth({ children }) {
//   const location = useLocation();
//   const { route } = useAuthenticator((context) => [context.route]);
//   if (route !== 'authenticated') {
//     console.log("RequireAuth: route is not authenticated, redirecting to login");
//     return <Navigate to="/login" state={{ from: location }} replace />;
//   }
//   return children;
// }

export function RequireAuth({ children }) {
  const location = useLocation();
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  if (authStatus !== 'authenticated') {
    // console.log("RequireAuth: route is not authenticated, redirecting to login");
    return <Navigate to="/login" state={{ from: location }} replace />;
    // return <Login />;
  }
  // console.log("RequireAuth: route is authenticated, rendering children");
  return children;
}
