import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NewResourceHeader } from "../components/header";
import ShowNotifications from "../components/notifications";
import { getResource, axiosPost } from "./common";
import {
  Form,
  SpaceBetween,
  ContentLayout,
  Header,
  Button,
  FormField,
  Input,
  Container,
  Select,
} from '@cloudscape-design/components';

function PostData(state) {
  const data = Object.keys(state.formData).reduce((acc, key) => {
    acc[key] = state.formData[key].value;
    return acc;
  }, {});
  // console.log("Will create new resource: ", data);
  state.setLoading(true);
  axiosPost(state.url, data)
  .then((response) => {
    if (response.data.error !== false) {
      // console.log(response.data.error);
      setLoading(false);
      ShowNotifications({
        child: state.url,
        flashbar: state.flashbar,
        header: `Error creating new ${state.resource}:`,
        message: response?.data?.result || "Unknown error",
        type: "error"
      });
    } else {
      ShowNotifications({
        child: state.url,
        flashbar: state.flashbar,
        header: `Created new ${state.resource}:`,
        message: data.name
      });
      state.setLoading(false);
      state.navigate("/" + state.resource);
    }
  })
  .catch((error) => {
    // console.log(error);
    state.setLoading(false);
    ShowNotifications({
      child: state.url,
      flashbar: state.flashbar,
      header: `Error creating new ${state.resource}:`,
      message: error.response?.data?.result || error.message,
      type: "error"
    });
  });
}

function ResourceSettings({ state }) {
  const defaultState = {
    name: { value: "", error: false, required: true },
    stackid: { value: null, error: false, required: true },
    type: { value: "", error: false, required: true },
    size: { value: "", error: false, required: true },
  };
  const [resourceSettingsData, setResourceSettingsData] = useState(defaultState);

  useEffect(() => {
    state.setFormData(resourceSettingsData);
    const validateValues = Object.keys(resourceSettingsData).every(key => resourceSettingsData[key].error === false);
    const validateRequired = Object.keys(resourceSettingsData).every(key =>
      resourceSettingsData[key].required === false ||
      resourceSettingsData[key].value !== "" &&
      resourceSettingsData[key].value !== null &&
      resourceSettingsData[key].value.length !== 0
    );
    if (validateValues && validateRequired) {
      state.setValidated(true);
    }else {
      state.setValidated(false);
    }
  }, [resourceSettingsData]);

  const onChange = (attribute, value, isValid) => {
    const newState = { ...resourceSettingsData };
    newState[attribute]['value'] = value;
    newState[attribute]['error'] = isValid ? false : true;
    setResourceSettingsData(newState);
  };

  const getErrorText = (field, errorMessage) => {
    return resourceSettingsData[field]['error'] ? errorMessage : undefined;
  };

  const [ stacks, setStacks ] = useState({ options: [], selected: null, status: "pending" });
  const [ types, setTypes ] = useState(
    {
      options: [
        { label: "Gluster", value: "gluster" },
        // { label: "AWS EFS", value: "efs" }, // dont want this yet
      ],
      selected: "",
    }
  );

  return (
    <Container
      id="new-resource-form"
      header={
        <Header
          variant="h2"
          areaLabel={'Information about the resource'}
        >
          {state.resource} Settings
        </Header>
      }
    >
      <SpaceBetween size="l">
        <FormField
          label="Name"
          // stretch={true}
          description="The name of the data store"
          errorText={getErrorText('name', "Name is required")}
          i18nStrings={{ errorIconAriaLabel: 'Error' }}
        >
          <Input
            value={resourceSettingsData.name.value}
            ariaRequired={true}
            placeholder="Name"
            onChange={({ detail: { value } }) => onChange('name', value, value.length > 0)}
          />
        </FormField>
        <FormField
          label="Stack"
          // stretch={true}
          description="Select the stack this data store belongs to"
          errorText={getErrorText("stackid", "Stack is required")}
          i18nStrings={{ errorIconAriaLabel: "Error" }}
        >
          <Select
            selectedOption={stacks.selected}
            placeholder="Choose a stack"
            onChange={({ detail }) => {
              setStacks({ ...stacks, selected: detail.selectedOption });
              onChange("stackid", detail.selectedOption.value, detail.selectedOption.value !== null);
            }}
            options={stacks.options}
            loadingText="Loading stacks"
            statusType={stacks.status}
            empty="No stacks available"
            errorText="Error fetching stacks"
            recoveryText="Retry"
            selectedAriaLabel="Selected"
            onLoadItems={() => { getResource({name: "stacks", resource: stacks, setResource: setStacks}); }}
          />
        </FormField>
        <FormField
          label="Type"
          // stretch={true}
          description="The type of this data store"
          errorText={getErrorText('type', "Type is required")}
          i18nStrings={{ errorIconAriaLabel: 'Error' }}
        >
          <Select
            selectedOption={types.selected}
            placeholder="Choose a type"
            onChange={({ detail }) => {
              setTypes({ ...types, selected: detail.selectedOption });
              onChange('type', detail.selectedOption.value, detail.selectedOption.value !== null);
            }}
            options={types.options}
            selectedAriaLabel="Selected"
          />
        </FormField>
        <FormField
          label="Size"
          // stretch={true}
          description="The total size of the storage in GB"
          errorText={getErrorText('size', "Size is required")}
          i18nStrings={{ errorIconAriaLabel: 'Error' }}
        >
          <Input
            value={resourceSettingsData.size.value}
            ariaRequired={true}
            placeholder="2000"
            onChange={({ detail: { value } }) => onChange('size', Number(value), value.length > 0)}
          />
        </FormField>
      </SpaceBetween>
    </Container>
  );
}

function BaseFormContent({ content, state}) {
  return (
    <form onSubmit={event => { event.preventDefault(); PostData(state)}}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={event => { event.preventDefault(); state.navigate("/" + state.resource)}}>
              Cancel
            </Button>
            <Button variant="primary" disabled={!state.validated} loading={state.loading}>Create {state.resource}</Button>
          </SpaceBetween>
        }
      >
        {content}
      </Form>
    </form>
  );
}

export function FormContent({ state }) {
  return (
    <BaseFormContent
      state={state}
      content={
        <SpaceBetween size="l">
          <ResourceSettings state={state}/>
        </SpaceBetween>
      }
    />
  );
}

export function NewStorageForm({ resource, url, flashbar }) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const state = {
    resource,
    url,
    validated,
    setValidated,
    navigate,
    formData,
    setFormData,
    loading,
    setLoading,
    flashbar,
  }
  return (
    <ContentLayout header={<NewResourceHeader resource={resource}/>}>
      <FormContent state={state}/>
    </ContentLayout>
  );
}
