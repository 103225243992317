import { useEffect, useState } from 'react';
import { loggedIn } from "../components/common";
import { NewSiteWizard } from "../components/new-site-wizard";
import { siteDetailsBreadcrumbs } from "../components/common";
import { useNavigate } from 'react-router-dom';

import {
  BreadcrumbGroup,
} from '@cloudscape-design/components';

import '../css/base.scss';

const Breadcrumbs = ({navigate, site}) => (
  <BreadcrumbGroup
    onFollow={event => {
      event.preventDefault();
      navigate(event.detail.href);
    }}
    items={siteDetailsBreadcrumbs(site)}
    expandAriaLabel="Show path"
    ariaLabel="Breadcrumbs" />
)

export default function NewSite({ state }) {
  const navigate = useNavigate();
  if (!loggedIn()) {
    return null;
  }
  useEffect(() => {
    state.contentType.setType("wizard");
    state.maxContentWidth.setWidth(null);
    state.activeHref.setHref("/sites");
    state.breadCrumbs.setCrumbs(
      <Breadcrumbs navigate={navigate} site="Create Site" />
    )
  }, []);
  return (
    <NewSiteWizard flashbar={state.flashBar}/>
  );
}
