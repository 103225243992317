import { useEffect, useState } from 'react';
import {
  ContentLayout,
  Button,
  ButtonDropdown,
  SpaceBetween,
} from '@cloudscape-design/components';

import { VersionsHeader } from "../components/header";
import { loggedIn, capitalizeFirst } from "../components/common";
import { TableContent } from "../components/table";
import DeleteModal from "../components/delete-modal";
import VersionEditModal from '../components/version-edit-modal';
import { deleteResource, putVersion } from '../components/common';

const tableResource = "versions";

const columnDefinitions = navigate => [
  { id: "id", header: "ID", cell: (e) => e.id, sortingField: "id" },
  { id: "productid", header: "Product ID", cell: (e) => e.productid, sortingField: "productid" },
  { id: "product", header: "Product", cell: (e) => e.product, sortingField: "product" },
  { id: "name", header: "Name", cell: (e) => e.name, sortingField: "name" },
  { id: "build", header: "Build", cell: (e) => e.build, sortingField: "build" },
  { id: "branch", header: "Branch", cell: (e) => e.branch, sortingField: "branch" },
  { id: "stackids", header: "Stack IDs", cell: (e) => e.stackids.join(", "), sortingField: "stackids" },
  { id: "stacks", header: "Stacks", cell: (e) => e.stacks.join(", "), sortingField: "stacks" },
  // this looks nice, but we dont have a route/page for individual stacks yet so /stacks/:id doesnt exist
  // { id: "stacks", header: "Stacks", cell: (e) => e.stacks.map((s, i) => <Link key={i} href={"/stacks/" + e.stackids[i]} onFollow={event => { event.preventDefault(); navigate(event.detail.href); }} >{s}</Link>).reduce((prev, curr) => [prev, ', ', curr]), sortingField: "stacks" },
  // multi-line formatted so its easier to read:
  // {
  //   id: "stacks",
  //   header: "Stacks",
  //   cell: (e) =>
  //     e.stacks
  //       .map((s, i) => (
  //         <Link
  //           key={i}
  //           href={"/stacks/" + e.stackids[i]}
  //           onFollow={(event) => {
  //             event.preventDefault();
  //             navigate(event.detail.href);
  //           }}
  //         >
  //           {s}
  //         </Link>
  //       ))
  //       .reduce((prev, curr) => [prev, ", ", curr]),
  //   sortingField: "stacks",
  // },
]

const visibleContent = [
  "product",
  "name",
  "build",
  "stacks",
]

const visibleContentItems = [
  { id: "id", label: "ID" },
  { id: "productid", label: "Product ID" },
  { id: "product", label: "Product", editable: false },
  { id: "name", label: "Name", editable: false },
  { id: "build", label: "Build", editable: false },
  { id: "branch", label: "Branch" },
  { id: "stackids", label: "Stack IDs", editable: true },
  { id: "stacks", label: "Stacks", editable: false },
]

const tableButtons = (props) => {
  /*
    Props available are:
    selectedItems: array of selected items
    loading: boolean - true if table is loading
    setReloadData: function to reload data. use SetReloadData(true)
    navigate: function to navigate to a page. passed to useNavigate()
    flashbar: functions to set flashbar items.
  */
  const [reload, setReload] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const onDeleteInit = () => setShowDeleteModal(true);
  const onDeleteDiscard = () => setShowDeleteModal(false);
  const onDeleteConfirm = () => {
    const deleted = props.selectedItems;
    props.setSelectedItems([]);
    setShowDeleteModal(false);
    deleteResource(
      deleted[0],
      capitalizeFirst(tableResource.slice(0, -1)),
      tableResource,
      props.flashbar,
      props.setReloadData
    );
  };

  const [showEditModal, setShowEditModal] = useState(false);
  const onEditInit = () => setShowEditModal(true);
  const onEditDiscard = () => setShowEditModal(false);
  const onEditConfirm = (newStacks) => {
    const editversion = props.selectedItems[0];
    props.setSelectedItems([]);
    setShowEditModal(false);
    // console.log("Will upgrade: ", editversion);
    // console.log("To version: ", newStacks.map(e => e.value));
    putVersion(
      editversion,
      { stackids: newStacks.map(e => e.value) },
      props.flashbar,
    )
    setTimeout(() => props.setReloadData(true), 1500)
  };

  useEffect(() => {
    if (reload) {
      props.setReloadData(true);
      setReload(false);
    }
  }, [reload]);

  return (
    <SpaceBetween size="xs" direction="horizontal">
      <DeleteModal
        visible={showDeleteModal}
        onDiscard={onDeleteDiscard}
        onDelete={onDeleteConfirm}
        items={props.selectedItems}
        type={tableResource.slice(0, -1)}
      />
      <VersionEditModal
        visible={showEditModal}
        onDiscard={onEditDiscard}
        onSubmit={onEditConfirm}
        resource={props.selectedItems?.[0]}
      />
      <Button
        loading={props.loading}
        iconName="refresh"
        onClick={() => { props.setReloadData(true) }}
      />
      <ButtonDropdown
        expandToViewport
        disabled={props.selectedItems.length === 0}
        items={[
          // { text: "Edit", id: "edit", disabled: props.selectedItems.length !== 1 },
          { text: "Delete", id: "delete", disabled: (props.selectedItems.length === 0) },
          { text: "Edit", id: "edit", disabled: (props.selectedItems.length !== 1), disabledReason: "Select an item to edit" },
        ]}
        onItemClick={event => {
          if (event.detail.id === "delete") {
            onDeleteInit();
          } else if (event.detail.id === "edit") {
            onEditInit();
          }
        }}
      >
        Actions
      </ButtonDropdown>
      <Button
        variant="primary"
        onClick={() => {
          props.navigate(`/${tableResource}/new`);
        }}
      >
        Create {capitalizeFirst(tableResource.slice(0, -1))}
      </Button>
    </SpaceBetween>
  );
}

export default function Versions({ state }) {
  if (!loggedIn()) {
    return null;
  }
  useEffect(() => {
    state.activeHref.setHref("/versions");
    state.breadCrumbs.setCrumbs(null);
    state.maxContentWidth.setWidth(Number.MAX_VALUE);
    state.contentType.setType("default");
  }, []);
  return (
    <ContentLayout header={<VersionsHeader/>}>
      <TableContent
        resource={tableResource}
        selectionType="single"
        api={{
          url: "versions?pretty=1",
          method: "get",
          // payload: null,
        }}
        columnDefinitions={columnDefinitions}
        visibleContent={visibleContent}
        visibleContentItems={visibleContentItems}
        buttons={tableButtons}
        flashbar={state.flashBar}
      />
    </ContentLayout>
  );
}
