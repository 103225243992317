import { useEffect, useState } from 'react';
import { axiosGet } from "./common";
import {
    Button,
    SpaceBetween,
    Modal,
    Box,
    Alert,
    ColumnLayout,
    FormField,
    Select,
  } from '@cloudscape-design/components';

function getVersions({site, resource, setResource}) {
  setResource({ ...resource, status: "loading" });
  axiosGet("versions/" + site.currentversionid)
  .then((response) => {
    if (response.data.error !== false) {
      console.log(response.data.error);
    } else {
      var productid = response.data.result[0].productid;
      var productversion = response.data.result[0].name.split("-")[1];
      axiosGet("versions?stackids=" + site.stackid + "&productid=" + productid)
      .then((response) => {
        if (response.data.error !== false) {
          console.log(response.data.error);
        } else {
          // options except where label number is less than or equal to current version
          var options = response.data.result.filter(e => e.name.split("-")[1] > productversion).map(e => { return { label: e.name, value: e.id } });
          var update = { options: options, status: "finished" }
          setResource({ ...resource, ...update, status: "finished" });
        }
      })
      .catch((error) => {
        // console.log(error);
        setResource({ ...resource, status: "error" });
      });
    }
  })
  .catch((error) => {
    // console.log(error);
    setResource({ ...resource, status: "error" });
  });
}

export default function UpgradeModal({ site, visible, onDiscard, onUpgrade }) {
  const defaultState = { value: "", selection: undefined, options: [], status: "pending", error: false, required: true };
  const [version, setVersion] = useState(defaultState);
  
  useEffect(() => {
    setVersion(defaultState);
  }, [visible]);

  if (!visible) {
    return null;
  }

  const handleUpgradeSubmit = event => {
    event.preventDefault();
    if (version.selection === undefined) {
      onDiscard();
    }else {
      onUpgrade(version.selection);
    }
  };

  return (
    <Modal
      visible={visible}
      onDismiss={onDiscard}
      header="Upgrade site"
      closeAriaLabel="Close dialog"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onDiscard}>Cancel</Button>
            <Button variant="primary" onClick={handleUpgradeSubmit}
              disabled={
                version.selection === undefined
              }
            >Upgrade</Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="m">
        <Box variant="span">
          Upgrade site{' '}
          <Box variant="span" fontWeight="bold">
            {site?.name}
          </Box>{' '}
          permanently? This action cannot be undone.
        </Box>

        <Alert type="warning">
          Proceeding with this action will upgrade the site with all content and can impact related resources.{' '}
        </Alert>

        <ColumnLayout columns={1}>
          <form onSubmit={handleUpgradeSubmit}>

          <FormField
            label="Target Version"
            // description="Every product can have multiple different code versons available."
            // errorText={formErrors.version}
            errorText={version.error ? "Please select a version" : undefined}
          >
            <Select
              placeholder="Select a version to upgrade to"
              selectedOption={version.selection}
              onChange={(e) => {
                setVersion({ ...version, selection: e.detail.selectedOption });
              }}
              options={version.options}
              statusType={version.status}
              loadingText="Loading product versions"
              empty="No product versions available"
              errorText="Error loading product versions"
              recoveryText="Retry"
              onLoadItems={() => { getVersions({site: site, resource: version, setResource: setVersion}); }}
            />
            </FormField>
          </form>
        </ColumnLayout>
      </SpaceBetween>
    </Modal>
  );
}
