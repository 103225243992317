// import { SideNavigation } from "@cloudscape-design/components";

/*
 * Learn more about Cloudscape Design System at
 * https://cloudscape.design
 */

import { useState, useEffect } from "react";
import TopNavigation from "@cloudscape-design/components/top-navigation";
import Toggle from "@cloudscape-design/components/toggle";
// import Icon from "@cloudscape-design/components/icon";
import { applyMode, applyDensity, Density, Mode } from '@cloudscape-design/global-styles';
// import Badge from "@cloudscape-design/components/badge";

import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';

// import "./css/topnavigation.scss";

export default function MyTopNavigation(props) {
  const navigate = useNavigate();
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [darkmode, setDarkmode] = useState(() => {
    return JSON.parse(localStorage.getItem("darkmode")) || true;
  });
  const [density, setDensity] = useState(() => {
    return JSON.parse(localStorage.getItem("density")) || false;
  });
  useEffect(() => {
    window.localStorage.setItem('darkmode', darkmode);
    applyMode(darkmode ? Mode.Dark : Mode.Light);
  }, [darkmode]);
  useEffect(() => {
    window.localStorage.setItem('density', density);
    applyDensity(density ? Density.Compact : Density.Comfortable);
  }, [density]);

  const utilities_settings = {
    type: "menu-dropdown",
    iconName: "settings",
    ariaLabel: "Settings",
    title: "Settings",
    expandToViewport: true,
    items: [
      {
        id: "settings-theme",
        text: <Toggle
                onChange={({ detail }) => {
                  setDarkmode(detail.checked)
                }}
                checked={darkmode}
                label="Dark Mode"
              >Dark Mode</Toggle>
      },
      {
        id: "settings-density",
        text: <Toggle
                onChange={({ detail }) => {
                  setDensity(detail.checked)
                }}
                checked={density}
                label="Compact Mode"
              >Compact Mode</Toggle>
      },
      // {
      //   id: "settings-project",
      //   text: "Project settings"
      // }
    ],
  }

  const utilities_loggedout = [
    {...utilities_settings},
    {
      type: "button",
      text: "Login",
      onClick: () => props.state.loginModal.setVisible(true)
    }
  ]

  const utilities_loggedin = [
      {...utilities_settings},
      {
        type: "menu-dropdown",
        text: (user && user.attributes && user.attributes.email) || "Unknown",
        // text: "test",
        iconName: "user-profile",
        items: [
          // { id: "profile", text: "Profile" },
          // { id: "preferences", text: "Preferences" },
          // { id: "security", text: "Security" },
          // {
          //   id: "support-group",
          //   text: "Support",
          //   items: [
          //     {
          //       id: "documentation",
          //       text: "Documentation",
          //       href: "#",
          //       external: true,
          //       externalIconAriaLabel:
          //         " (opens in new tab)"
          //     },
          //     { id: "support", text: "Support" },
          //     {
          //       id: "feedback",
          //       text: "Feedback",
          //       href: "#",
          //       external: true,
          //       externalIconAriaLabel:
          //         " (opens in new tab)"
          //     }
          //   ]
          // },
          { id: "signout", text: "Logout", href: "#" }
        ],
        onItemClick: ({ detail }) => {
          if (detail.id === "signout") {
            signOut();
            // navigate("/landing");
          }
        }
      }
    ]
    return (
      <TopNavigation
        identity={{
          href: "/",
          title: "eThink Cloud",
          onFollow: (event) => {
            event.preventDefault();
            navigate("/");
          }
        }}
        utilities={user ? utilities_loggedin : utilities_loggedout}
        i18nStrings={{
          searchIconAriaLabel: "Search",
          searchDismissIconAriaLabel: "Close search",
          overflowMenuTriggerText: "More",
          overflowMenuTitleText: "All",
          overflowMenuBackIconAriaLabel: "Back",
          overflowMenuDismissIconAriaLabel: "Close menu"
        }}
      />
    );
}
