import { useEffect, useState } from 'react';

import {
    Button,
    SpaceBetween,
    Modal,
    Box,
    Alert,
    ColumnLayout,
    FormField,
    Input,
  } from '@cloudscape-design/components';

export default function RestoreModal({ items, visible, onDiscard, onRestore }) {
  const restoreConsentText = 'confirm';
  const type = 'backup';

  const [restoreInputText, setRestoreInputText] = useState('');
  useEffect(() => {
    setRestoreInputText('');
  }, [visible]);

  const handleRestoreSubmit = event => {
    event.preventDefault();
    if (inputMatchesConsentText) {
      onRestore();
    }
  };

  const inputMatchesConsentText = restoreInputText.toLowerCase() === restoreConsentText;

  const isMultiple = items.length > 1;
  return (
    <Modal
      visible={visible}
      onDismiss={onDiscard}
      header={isMultiple ? `Restore ${type}s` : `Restore ${type}`}
      closeAriaLabel="Close dialog"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onDiscard}>Cancel</Button>
            <Button variant="primary" onClick={onRestore} disabled={!inputMatchesConsentText}>Restore</Button>
          </SpaceBetween>
        </Box>
      }
    >
      {items.length > 0 && (
        <SpaceBetween size="m">
          {isMultiple ? (
            <Box variant="span">
              Restore{' '}
              <Box variant="span" fontWeight="bold">
                {items.length} {type}s
              </Box>{' '}
              permanently? This action cannot be undone.
            </Box>
          ) : (
            <Box variant="span">
              Restore {type}{' '}
              <Box variant="span" fontWeight="bold">
                {items[0].name}
              </Box>{' '}
              permanently? This action cannot be undone.
            </Box>
          )}

          <Alert type="warning">
            Proceeding with this action will restore {type}(s) with all content and can impact related resources.{' '}
          </Alert>

          <Box>To avoid accidental restorations we ask you to provide additional written consent.</Box>

          <ColumnLayout columns={2}>
            <form onSubmit={handleRestoreSubmit}>
              <FormField label={`Type "${restoreConsentText}" to agree.`}>
                <Input
                  placeholder={restoreConsentText}
                  autoFocus={true}
                  autoComplete={false}
                  onChange={event => setRestoreInputText(event.detail.value)}
                  value={restoreInputText}
                  ariaRequired={true}
                  ariaLabelledby="restore-confirmation-label"
                />
              </FormField>
            </form>
          </ColumnLayout>
        </SpaceBetween>
      )}
    </Modal>
  );
}
