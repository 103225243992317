import { useState, useEffect }from "react";
import {
  axiosGet,
  axiosPost,
  capitalizeFirst,
} from "./common";
import { useNavigate } from "react-router-dom";
import { useCollection } from "@cloudscape-design/collection-hooks";
import {
  Box,
  Button,
  CollectionPreferences,
  Header,
  Pagination,
  Table,
  TextFilter,
  PropertyFilter,
  Toggle,
} from '@cloudscape-design/components';

export function checkSelectedItems(selectedItems, key, value) {
  if (selectedItems.length === 0) {
    return false;
  }
  return selectedItems.every((e) => e[key] === value);
}

const paginationLabels = {
  nextPageLabel: "Next page",
  previousPageLabel: "Previous page",
  pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`
}

const PROPERTY_FILTERING_I18N_CONSTANTS = {
  filteringAriaLabel: 'your choice',
  dismissAriaLabel: 'Dismiss',

  filteringPlaceholder: 'Search',
  groupValuesText: 'Values',
  groupPropertiesText: 'Properties',
  operatorsText: 'Operators',

  operationAndText: 'and',
  operationOrText: 'or',

  operatorLessText: 'Less than',
  operatorLessOrEqualText: 'Less than or equal',
  operatorGreaterText: 'Greater than',
  operatorGreaterOrEqualText: 'Greater than or equal',
  operatorContainsText: 'Contains',
  operatorDoesNotContainText: 'Does not contain',
  operatorEqualsText: 'Equals',
  operatorDoesNotEqualText: 'Does not equal',

  editTokenHeader: 'Edit filter',
  propertyText: 'Property',
  operatorText: 'Operator',
  valueText: 'Value',
  cancelActionText: 'Cancel',
  applyActionText: 'Apply',
  allPropertiesLabel: 'All properties',

  tokenLimitShowMore: 'Show more',
  tokenLimitShowFewer: 'Show fewer',
  clearFiltersText: 'Clear filters',
  removeTokenButtonAriaLabel: () => 'Remove token',
  enteredTextLabel: text => `Use: "${text}"`,
};

function EmptyState({ title, subtitle, action }) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
}

function loadData(props) {
  var baseURL = props.api.url;
  var method = props.api.method;
  if (props.api.hasOwnProperty("payload")) {
    var payload = props.api.payload;
  }
  if (!props?.silentreload) {
    props.setLoading(true)
  }
  if (method === "get") {
    axiosGet(baseURL)
    .then((response) => {
      if (response.data.error !== false) {
        props.setEmptyStateText(
          <EmptyState
            title={`Failed to load ${props.resource}`}
            subtitle="Error from server, please try again later."
            action={<Button onClick={() => loadData(props)}>Try again</Button>}
          />
        )
        props.setLoading(false);
      }else {
        props.setallItems(response.data.result);
        props.setLoading(false);
      }
    })
    .catch((error) => {
      var apiError = "Unknown error";
      try {
        var apiError = error.response.data.result;
      } catch (e) {
        console.log(e);
      }
      props.setEmptyStateText(
        <EmptyState
          title={`Failed to load ${props.resource}`}
          subtitle={error.message + ": " + apiError}
          // subtitle={<React.Fragment><Icon name="status-negative" variant="error" /> {error.message}</React.Fragment> }
          action={<Button onClick={() => loadData(props)}>Try again</Button>}
        />
      )
      props.setLoading(false);
    });
  }else if (method === "post") {
    // TODO: impliment axiosPost
    axiosPost(baseURL, payload)
    .then((response) => {
      if (response.data.error !== false) {
        props.setEmptyStateText(
          <EmptyState
            title={`Failed to load ${props.resource}`}
            subtitle="Error from server, please try again later."
            action={<Button onClick={() => loadData(props)}>Try again</Button>}
          />
        )
        props.setLoading(false);
      }else {
        props.setallItems(response.data.result);
        props.setLoading(false);
      }
    })
    .catch((error) => {
      var apiError = "Unknown error";
      try {
        var apiError = error.response.data.result;
      } catch (e) {
        console.log(e);
      }
      props.setEmptyStateText(
        <EmptyState
          title={`Failed to load ${props.resource}`}
          subtitle={error.message + ": " + apiError}
          action={<Button onClick={() => loadData(props)}>Try again</Button>}
        />
      )
      props.setLoading(false);
    });
  }
}

export function TableContent({ resource, selectionType, stickyHeader, variant, api, columnDefinitions, visibleContent, visibleContentItems, buttons, buttonprops, flashbar, filterType}) {
  const navigate = useNavigate();
  const [allItems, setallItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState(`Loading ${capitalizeFirst(resource)}`);
  const [emptyStateText, setEmptyStateText] = useState(
    <EmptyState
      title="No Data"
      subtitle={`No ${resource} found.`}
      // action={<Button>Create {capitalizeFirst(resource.slice(0, -1))}</Button>}
    />
  );
  const [reloadData, setReloadData] = useState(false);

  useEffect(() => {
    loadData({resource, api, setLoading, setLoadingText, setallItems, setEmptyStateText});
  }, []);

  useEffect(() => {
    if (reloadData === true) {
      loadData({resource, api, setLoading, setLoadingText, setallItems, setEmptyStateText});
      setReloadData(false);
    }
  }, [reloadData]);

  useEffect(() => {
    if (allItems.some(item => item?.status === "pending")) {
      const intervalId = setInterval(() => {
        // console.log("Reloading data because some items are pending");
        loadData({resource, api, setLoading, setLoadingText, setallItems, setEmptyStateText, silentreload: true});
      }, 10000);
      return () => clearInterval(intervalId);
    }
  }, [allItems]);

  const columns = columnDefinitions(navigate);
  const [ filteringType, setFilteringType ] = useState("none");

  if (filteringType === "none") {
    if (filterType === "properties") {
      setFilteringType({
        propertyFiltering: {
          filteringProperties: Object.keys(columns).map((key, index) => {
            return {
              propertyLabel: columns[key].header,
              key: columns[key].id,
              groupValuesLabel: `${columns[key].header} values`,
              operators:
                (columns[key].id === "missing") ? (
                  ['=', '!=']
                ) : (columns[key].id === "deleted") ? (
                  ['=', '!=']
                ) : (columns[key].id === "instances") ? (
                  ['=', '!=', '>', '<', '>=', '<=']
                ) : (columns[key].id === "standard") ? (
                  ['=', '!=']
                ) : (columns[key].id === "version") ? (
                  ['>', '<', '>=', '<=', ':', '!:']
                ) : (columns[key].id === "id") ? (
                  ['=', '!=']
                ) : (columns[key].id === "epoch") ? (
                  ['>', '<', '>=', '<=', ':', '!:']
                ) : (
                  [':', '!:', '=', '!=']
                )
            }
          }),
          empty: ( emptyStateText ),
          noMatch: (
            <EmptyState
              title="No matches"
              subtitle="We can’t find a match."
              action={<Button onClick={() => { actions.setPropertyFiltering({ tokens: [], operation: 'and' }); }}>Clear filter</Button>}
            />
          ),
        },
      });
    }else {
      setFilteringType({
        filtering: {
          empty: (
            emptyStateText
          ),
          noMatch: (
            <EmptyState
              title="No matches"
              subtitle="We can’t find a match."
              action={<Button onClick={() => { setFilteringText(''); actions.setFiltering('') }}>Clear filter</Button>}
            />
          ),
        }
      });
    }
  
  }

  // const [preferences, setPreferences] = useState({ pageSize: 10, visibleContent: visibleContent });
  const defaultPreferences = {
    pageSize: 10,
    wrapLines: false,
    visibleContent: visibleContent
  };
  const [preferences, setPreferences] = useState(() => {
    return JSON.parse(localStorage.getItem("tablePreferences_" + resource)) || defaultPreferences;
  });
  const [filteringText, setFilteringText] = useState("");
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps } = useCollection(
    allItems,
    {
      ...filteringType,
      pagination: { pageSize: preferences.pageSize },
      sorting: { },
      selection: {},
    }
  );

  const { selectedItems } = collectionProps;
  const { setSelectedItems } = actions;

  useEffect(() => {
    window.localStorage.setItem("tablePreferences_" + resource, JSON.stringify(preferences));
  }, [preferences]);

  return (
    <Table
      {...collectionProps}
      selectionType={selectionType === "none" ? undefined : selectionType}
      stickyHeader={stickyHeader !== undefined ? stickyHeader : false}
      variant={variant !== undefined ? variant : undefined}
      header={
        <Header
          counter={selectedItems.length ? `(${selectedItems.length}/${allItems.length})` : `(${allItems.length})`}
          actions={buttons({selectedItems, setSelectedItems, loading, setReloadData, navigate, flashbar, ...buttonprops})
          }
        >
          {capitalizeFirst(resource)}
        </Header>
      }
      columnDefinitions={columns}
      visibleColumns={preferences.visibleContent}
      wrapLines={preferences.wrapLines}
      items={items}
      loading={loading}
      loadingText={loadingText}
      pagination={
        <Pagination
          currentPageIndex={currentPageIndex}
          pagesCount={Math.ceil(filteredItemsCount / preferences.pageSize)}
          ariaLabels={paginationLabels}
          onChange={({ detail }) => {
            setCurrentPageIndex(detail.currentPageIndex)
            actions.setCurrentPage(detail.currentPageIndex)
          }}
        />
      }
      filter={
        filterType === "properties" ? (
          <PropertyFilter
            i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
            {...propertyFilterProps}          
            countText={`${filteredItemsCount} matches`}
            expandToViewport={true}
            tokenLimit={3}
            virtualScroll={true}
            // onChange={({ detail }) => setQuery(detail) }
          />
        ) : (
          <TextFilter
            filteringText={filteringText}
            filteringPlaceholder={`Find ${capitalizeFirst(resource)}`}
            countText={`${filteredItemsCount} matches`}
            filteringAriaLabel={`Filter ${capitalizeFirst(resource)}`}
            onChange={({ detail }) => {
              setFilteringText(detail.filteringText)
              actions.setFiltering(detail.filteringText)
            }}
          />
        )
      }
      preferences={
        <CollectionPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={preferences}
          customPreference={(resetPreferences, setResetPreferences) => (
            // <Button onClick={() => { setPreferences(defaultPreferences); }}>Reset preferences to default</Button>
            <Toggle
            checked={resetPreferences}
            onChange={({ detail }) => {
              setResetPreferences(detail.checked);
              // setPreferences(defaultPreferences);
            }}
          >
            Reset preferences to default
          </Toggle>
          )}
          onConfirm={({ detail }) => {
            if (detail?.custom) {
              setPreferences(defaultPreferences);
            } else {
              delete detail.custom;
              setPreferences(detail);
            }
            console.log(detail);
          }}
          pageSizePreference={{
            title: "Select page size",
            options: [
              // { value: allItems.length, label: "All resources", disabled: allItems.length > 100 },
              { value: 100, label: "All resources (100 max)", disabled: allItems.length > 100 },
              { value: 5, label: "5 resources" },
              { value: 10, label: "10 resources" },
              { value: 20, label: "20 resources" },
              { value: 50, label: "50 resources" },
            ]
          }}
          wrapLinesPreference={{
            label: "Wrap lines",
          }}
          visibleContentPreference={{
            title: "Select visible content",
            options: [
              {
                options: visibleContentItems
              }
            ]
          }}
        />
      }
      // isItemDisabled={
      //   // TODO: turn this into a component function thingy
      //   (item) => item.status === "pending"
      // }
    />
  );
}
