// import { useState, useEffect } from "react";
import { Modal } from "@cloudscape-design/components";
import { Authenticator } from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";


export default function LoginModal(props) {
  return (
    <Modal
      onDismiss={() => props.state.loginModal.setVisible(false)}
      visible={props.state.loginModal.visible}
      closeAriaLabel="Close modal"
      // footer={
      //   <Box float="right">
      //     <SpaceBetween direction="horizontal" size="xs">
      //       <Button variant="link">Cancel</Button>
      //       <Button variant="primary">Ok</Button>
      //     </SpaceBetween>
      //   </Box>
      // }
      // header="Sign In"
    >
      {/* <Authenticator socialProviders={['amazon', 'apple', 'facebook', 'google']} /> */}
      <Authenticator socialProviders={['google']} hideSignUp={true}>
      {() => {
          if (props.state.loginModal.visible) {
            props.state.loginModal.setVisible(false);
          }
      }
      }
      </Authenticator>
      <br />
    </Modal>
  );
}
