import { useEffect, useState } from 'react';
import {
  ContentLayout,
  Button,
  SpaceBetween,
} from '@cloudscape-design/components';

import { EventsHeader } from "../components/header";
import { loggedIn } from "../components/common";
import { TableContent } from "../components/table";

const tableResource = "events";

const columnDefinitions = navigate => [
  { id: "id", header: "ID", cell: (e) => e.id, sortingField: "id" },
  // { id: "timestamp", header: "Time", cell: (e) => Date(e.timestamp * 1000).toLocaleString(), sortingField: "time" },
  { id: "timestamp", header: "Time", cell: (e) => new Date(e.timestamp).toLocaleString('en-US', {"dateStyle": "medium", "timeStyle": "long"}), sortingField: "timestamp" },
  { id: "siteid", header: "Site ID", cell: (e) => e.siteid, sortingField: "siteid" },
  { id: "site", header: "Site", cell: (e) => e.site, sortingField: "site" },
  { id: "username", header: "Username", cell: (e) => e.username, sortingField: "username" },
  { id: "type", header: "Type", cell: (e) => e.type, sortingField: "type" },
  { id: "event", header: "Event", cell: (e) => {
    if (e.type == "token") {
      const event = JSON.parse(e.event);
      return event.action + ": " + event.log;
    }
    return e.event;
  }, sortingField: "event" },
]

const visibleContent = [
  "timestamp",
  "site",
  "username",
  "type",
  "event",
]

const visibleContentItems = [
  { id: "id", label: "ID" },
  { id: "siteid", label: "Site ID" },
  { id: "site", label: "Site", editable: false },
  { id: "username", label: "Username", editable: false },
  { id: "type", label: "Type", editable: false },
  { id: "event", label: "Event", editable: false },
]

const tableButtons = (props) => {
  /*
    Props available are:
    selectedItems: array of selected items
    loading: boolean - true if table is loading
    setReloadData: function to reload data. use SetReloadData(true)
    navigate: function to navigate to a page. passed to useNavigate()
    flashbar: functions to set flashbar items.
  */
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (reload) {
      props.setReloadData(true);
      setReload(false);
    }
  }, [reload]);

  return (
    <SpaceBetween size="xs" direction="horizontal">
      <Button
        loading={props.loading}
        iconName="refresh"
        onClick={() => { props.setReloadData(true) }}
      />
    </SpaceBetween>
  );
}

export default function Events({ state }) {
  if (!loggedIn()) {
    return null;
  }
  useEffect(() => {
    state.activeHref.setHref("/events");
    state.breadCrumbs.setCrumbs(null);
    state.maxContentWidth.setWidth(Number.MAX_VALUE);
    state.contentType.setType("default");
  }, []);
  return (
    <ContentLayout header={<EventsHeader/>}>
      <TableContent
        resource={tableResource}
        selectionType={undefined}
        api={{
          url: "events?pretty=1",
          method: "get",
          // payload: null,
        }}
        columnDefinitions={columnDefinitions}
        visibleContent={visibleContent}
        visibleContentItems={visibleContentItems}
        buttons={tableButtons}
        flashbar={state.flashBar}
      />
    </ContentLayout>
  );
}
