import { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route, Navigate } from "react-router-dom";
import useNotifications from "./components/useNotifications";
// import { useAuthenticator } from '@aws-amplify/ui-react';
// import { Auth } from 'aws-amplify';
import "@aws-amplify/ui-react/styles.css";

import Dashboard from "./pages/Dashboard";
import Landing from "./pages/Landing";
import Sites from "./pages/Sites";
import SiteDetails from "./pages/SiteDetails";
import Products from "./pages/Products";
import Versions from "./pages/Versions";
import Regions from "./pages/Regions";
import Stacks from "./pages/Stacks";
import Databases from "./pages/Databases";
import Storage from "./pages/Storage";
import S3buckets from "./pages/S3buckets";
import Loadbalancers from "./pages/Loadbalancers";
import NewSite from "./pages/NewSite";
import Cachestores from "./pages/Cachestores";
import NewProduct from "./pages/NewProduct";
import NewVersion from "./pages/NewVersion";
import NewRegion from "./pages/NewRegion";
import NewStack from "./pages/NewStack";
import NewDatabase from "./pages/NewDatabase";
import NewCachestore from "./pages/NewCachestore";
import NewLoadbalancer from "./pages/NewLoadbalancer";
import NewStorage from "./pages/NewStorage";
import NewS3bucket from "./pages/NewS3bucket";
import Events from "./pages/Events";

import { RequireAuth } from './RequireAuth';
import { Login } from './pages/Login';
import MyTopNavigation from "./TopNavigation";
import SideNavigation from "./SideNavigation";
import LoginModal from "./LoginModal";
import { AppLayout, Flashbar } from "@cloudscape-design/components";

/*
 * Learn more about Cloudscape Design System at
 * https://cloudscape.design
 */


export default function App() {
  const [loginVisible, setLoginVisible] = useState(false);
  const [activeHref, setActiveHref] = useState("/");
  const [ flashbarItems, setFlashbarItems ] = useNotifications();

  const [breadcrumbs, setBreadcrumbs] = useState(null);
  const [contentType, setContentType] = useState("default");
  const [maxContentWidth, setMaxContentWidth] = useState(Number.MAX_VALUE);
  const state = {
    loginModal: {
      visible: loginVisible,
      setVisible: setLoginVisible
    },
    flashBar: {
      flashbarItems: flashbarItems,
      setFlashbarItems: setFlashbarItems,
    },
    breadCrumbs: {
      crums: breadcrumbs,
      setCrumbs: setBreadcrumbs
    },
    activeHref: {
      href: activeHref,
      setHref: setActiveHref
    },
    contentType: {
      type: contentType,
      setType: setContentType
    },
    maxContentWidth: {
      width: maxContentWidth,
      setWidth: setMaxContentWidth
    }
  };

  useEffect(() => {
    state.breadCrumbs.setCrumbs(null);
    state.maxContentWidth.setWidth(Number.MAX_VALUE);
    state.contentType.setType("default");
  }, [activeHref]);

  return (
    <Router>
      <LoginModal state={state} />
      <MyTopNavigation state={state} />
      <AppLayout
        toolsHide={true}
        headerSelector="#header"
        stickyNotifications={true}
        notifications={<Flashbar items={flashbarItems?.[activeHref.startsWith('/') ? activeHref.slice(1) : activeHref] ?? []} />}
        // notifications={notifications}
        navigation={<SideNavigation state={state} activeHref={activeHref} />}
        navigationWidth={200}
        maxContentWidth={maxContentWidth}
        breadcrumbs={breadcrumbs}
        contentType={contentType}
        content={
          <Routes>
            <Route path="/login" element={<Login state={state} />} />
            <Route path="/landing" element={<Landing state={state} />} />
            <Route exact path="dashboard" element={<RequireAuth><Dashboard state={state} /></RequireAuth>} />
            <Route exact path="sites" element={<RequireAuth><Sites state={state} /></RequireAuth>} />
            <Route exact path="sites/new" element={<RequireAuth><NewSite state={state} /></RequireAuth>} />
            <Route exact path="sites/:siteId" element={<RequireAuth><SiteDetails state={state} /></RequireAuth>} />
            <Route exact path="products" element={<RequireAuth><Products state={state} /></RequireAuth>} />
            <Route exact path="products/new" element={<RequireAuth><NewProduct state={state}/></RequireAuth>} />
            <Route exact path="versions" element={<RequireAuth><Versions state={state} /></RequireAuth>} />
            <Route exact path="versions/new" element={<RequireAuth><NewVersion state={state}/></RequireAuth>} />
            <Route exact path="regions" element={<RequireAuth><Regions state={state} /></RequireAuth>} />
            <Route exact path="regions/new" element={<RequireAuth><NewRegion state={state}/></RequireAuth>} />
            <Route exact path="stacks" element={<RequireAuth><Stacks state={state} /></RequireAuth>} />
            <Route exact path="stacks/new" element={<RequireAuth><NewStack state={state}/></RequireAuth>} />
            <Route exact path="databases" element={<RequireAuth><Databases state={state} /></RequireAuth>} />
            <Route exact path="databases/new" element={<RequireAuth><NewDatabase state={state}/></RequireAuth>} />
            <Route exact path="loadbalancers" element={<RequireAuth><Loadbalancers state={state} /></RequireAuth>} />
            <Route exact path="loadbalancers/new" element={<RequireAuth><NewLoadbalancer state={state}/></RequireAuth>} />
            <Route exact path="storage" element={<RequireAuth><Storage state={state} /></RequireAuth>} />
            <Route exact path="storage/new" element={<RequireAuth><NewStorage state={state}/></RequireAuth>} />
            <Route exact path="s3buckets" element={<RequireAuth><S3buckets state={state} /></RequireAuth>} />
            <Route exact path="s3buckets/new" element={<RequireAuth><NewS3bucket state={state}/></RequireAuth>} />
            <Route exact path="cachestores" element={<RequireAuth><Cachestores state={state} /></RequireAuth>} />
            <Route exact path="cachestores/new" element={<RequireAuth><NewCachestore state={state} /></RequireAuth>} />
            <Route exact path="events" element={<RequireAuth><Events state={state} /></RequireAuth>} />
            <Route exact path="/" element={<Landing state={state} />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        }
      />
    </Router>
  );
}
