import { v4 as uuid } from 'uuid';
// import useNotifications from './useNotifications';

export default function ShowNotifications({child, flashbar, header, message, callback=null, type="success"}) {
  const id = uuid();
  const items = flashbar.flashbarItems;
  // if child is not a key of items, log it
  if (items[child] === undefined) {
    if (child === "lbhosts") {
      child = "loadbalancers";
    } else if (child === "dbhosts") {
      child = "databases";
    } else if (child === "cachehosts") {
      child = "cachestores";
    } else if (child === "dataroots") {
      child = "storage";
    } else if (child === "backups") {
      child = "sites";
    } else {
      console.log(`Invalid child passed to ShowNotifications: ${child}`);
      return;
    }
  }
  const newItem = {
    type: type,
    dismissible: true,
    statusIconAriaLabel: 'success',
    dismissLabel: 'Dismiss message',
    header: header,
    content: message,
    onDismiss: () => {
      flashbar.setFlashbarItems({type: 'remove', payload: {child: child, item: {id: id}}});
      if (typeof callback === 'function') {
        callback();
      }
    },
    id: id,
  }
  flashbar.setFlashbarItems({type: 'add', payload: {child: child, item: newItem}});
}
