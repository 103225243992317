import { useEffect, useState } from 'react';
import { ContentLayout, Container, Header, SpaceBetween } from "@cloudscape-design/components";

export default function Landing({state}) {
  useEffect(() => {
    state.activeHref.setHref("/");
  }, []);
  return (
    <ContentLayout
      header={
        <SpaceBetween direction="vertical" size="xxs">
        <br />
        <Header variant="h1">Managed Hosting v2 Cloud Control</Header>
      </SpaceBetween>
      }
    >
      <Container
        header={
          <Header variant="h2">
            Welcome
          </Header>
        }
      >
        There is no content on this page. please login to access the dashboard.
      </Container>
    </ContentLayout>
  );
}
