// import React from 'react';
import { TableContent } from './table';
import { SpaceBetween, Button } from '@cloudscape-design/components';

const tableResource = "Stats";

const columnDefinitions = navigate => [
  // { id: "id", header: "ID", cell: (e) => e.id, sortingField: "id" },
  // { id: "wwwroot", header: "URL", cell: (e) => e.wwwroot, sortingField: "url" },
  { id: "component", header: "Component", cell: (e) => e.component, sortingField: "component" },
  { id: "name", header: "Name", cell: (e) => e.name, sortingField: "name" },
  { id: "value", header: "Value", cell: (e) => e.value, sortingField: "Value" },
]

const visibleContentItems = [
  // { id: "id", label: "ID" },
  // { id: "wwwroot", label: "URL" },
  { id: "component", label: "Component" },
  { id: "name", label: "Name", editable: false },
  { id: "value", label: "Value" },
]

const visibleContent = [
  'component',
  'name',
  'value',
]

const tableButtons = (props) => {
  return (
    <SpaceBetween size="xs" direction="horizontal">
      <Button
        loading={props.loading}
        iconName="refresh"
        onClick={() => { props.setReloadData(true) }}
      />
    </SpaceBetween>
  )
}


export default function SiteStats({site}) {
  return (
    <TableContent
    resource={tableResource}
    selectionType="none"
    stickyHeader={true}
    variant="embedded"
    api={{
      url: "stats/sites",
      method: "post",
      payload: {
        wwwroot: site,
      },
    }}
    columnDefinitions={columnDefinitions}
    visibleContent={visibleContent}
    visibleContentItems={visibleContentItems}
    buttons={tableButtons}
    flashbar={null}
    filterType="properties"
    />
  )
}
