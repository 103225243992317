import { useEffect, useState } from 'react';
import { getResource } from "./common";
import {
    Button,
    SpaceBetween,
    Modal,
    Box,
    Alert,
    ColumnLayout,
    FormField,
    Multiselect,
  } from '@cloudscape-design/components';

export default function VersionEditModal({ resource, visible, onDiscard, onSubmit }) {
  const defaultState = { value: [], selection: undefined, options: [], status: "pending", error: false, required: true };
  const [stacks, setStacks] = useState(defaultState);
  
  useEffect(() => {
    if (visible) {
      getResource({name: "stacks", resource: stacks, setResource: setStacks});
    } else {
      setStacks(defaultState);
    }
  }, [visible]);

  useEffect(() => {
    // pre-select the current stacks for this version
    if (stacks.status === "finished" && stacks.selected === undefined) {
      // console.log(stacks);
      var selected = stacks.options.filter(e => resource.stackids.includes(e.value));
      setStacks({ ...stacks, selected: selected });
    }
  }, [stacks]);

  if (!visible) {
    return null;
  }

  const handleSubmit = event => {
    event.preventDefault();
    if (stacks.selected?.length < 1) {
      onDiscard();
    }else {
      // console.log stacks.selected.each value:
      //console.log(stacks.selected.map(e => e.value));
      onSubmit(stacks.selected);
    }
  };

  return (
    <Modal
      visible={visible}
      onDismiss={onDiscard}
      header="Edit Version"
      closeAriaLabel="Close dialog"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onDiscard}>Cancel</Button>
            <Button variant="primary" onClick={handleSubmit}
              disabled={
                stacks.selected?.length < 1 ||
                stacks.status === "loading"
              }
            >Save</Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="m">
        <Box variant="span">
          Edit version{' '}
          <Box variant="span" fontWeight="bold">
            {resource?.name}
          </Box>{' '}
          permanently? This action cannot be undone.
        </Box>

        <Alert type="warning">
          Proceeding with this action will edit the resource with all content and can impact related resources.{' '}
        </Alert>

        <ColumnLayout columns={1}>
          <form onSubmit={handleSubmit}>

          {/* <FormField
            label="Target Version"
            // description="Every product can have multiple different code versons available."
            // errorText={formErrors.version}
            errorText={version.error ? "Please select a version" : undefined}
          >
            <Select
              placeholder="Select a version to upgrade to"
              selectedOption={version.selection}
              onChange={(e) => {
                setVersion({ ...version, selection: e.detail.selectedOption });
              }}
              options={version.options}
              statusType={version.status}
              loadingText="Loading product versions"
              empty="No product versions available"
              errorText="Error loading product versions"
              recoveryText="Retry"
              onLoadItems={() => { getVersions({site: site, resource: version, setResource: setVersion}); }}
            />
            </FormField> */}
        <FormField
          label="Stacks"
          // stretch={true}
          description={`The stacks this version will be deployed to`}
          errorText={stacks.error ? "Please select a stack" : undefined}
          i18nStrings={{ errorIconAriaLabel: 'Error' }}
        >
          <Multiselect
            placeholder="Choose one or more stacks"
            selectedOptions={stacks.selected}
            onChange={({ detail }) => {
              setStacks({ ...stacks, selected: detail.selectedOptions });
              // onChange("stackids", detail.selectedOptions.map(e => e.value), detail.selectedOptions.length > 0);
            }}
            options={stacks.options}
            loadingText="Loading stacks"
            statusType={stacks.status}
            empty="No stacks available"
            errorText="Error fetching stacks"
            recoveryText="Retry"
            selectedAriaLabel="Selected"
            // onLoadItems={() => { getResource({name: "stacks", resource: stacks, setResource: setStacks}); }}
          />
        </FormField>
          </form>
        </ColumnLayout>
      </SpaceBetween>
    </Modal>
  );
}
