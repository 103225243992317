import { useEffect } from "react";

import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { useNavigate, useLocation } from 'react-router';

import { ContentLayout, Container } from "@cloudscape-design/components";
import { LoginHeader } from "../components/header";

export function Login({ state }) {
  const { route } = useAuthenticator(context => [context.authStatus]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || '/';
  // useEffect(() => {
  //   state.activeHref.setHref("login");
  // }, []);
  useEffect(() => {
    if (route === 'authenticated') {
      // console.log('authenticated, navigating to ', from);
      if ( location.state?.from ) {
        // this includes the entire state from RequireLogin.jsx, including Query String and others.
        navigate({...location.state.from}, {replace: true});
      }else {
        navigate(from, { replace: true });
      }
    }
  }, [route, navigate, from]);

  if (route !== 'authenticated') {
    return (
      <ContentLayout header={<LoginHeader/>}>
        <Container><Authenticator socialProviders={['google']} hideSignUp={true}></Authenticator></Container>
      </ContentLayout>
    );
  }
}
