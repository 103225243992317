// import React from 'react';
import { Box, Container, Header, ColumnLayout, Spinner } from '@cloudscape-design/components';
import { CounterLink } from './common';

export default function ServiceOverview({data}) {
  const spinner = <Spinner size="large" />;
  // console.log(data);
  return (
    <Container
      className="custom-dashboard-container"
      // variant="stacked"
      header={
        <Header variant="h2">
          Resources
        </Header>
      }
    >
      <ColumnLayout columns="3" variant="text-grid">
        <div style={{textAlign: 'center'}}>
          <Box variant="awsui-key-label">Sites</Box>
          <CounterLink link="/sites">
            {data?.sites?.toString ? data.sites : spinner }

          </CounterLink>
        </div>
        <div style={{textAlign: 'center'}}>
          <Box variant="awsui-key-label">Products</Box>
          <CounterLink link="/products">
            {data?.products?.toString ? data.products : spinner}
          </CounterLink>
        </div>
        <div style={{textAlign: 'center'}}>
          <Box variant="awsui-key-label">Versions</Box>
          <CounterLink link="/versions">
            {data?.versions?.toString ? data.versions : spinner}
          </CounterLink>
        </div>
        <div style={{textAlign: 'center'}}>
          <Box variant="awsui-key-label">Regions</Box>
          <CounterLink link="/regions">
            {data?.regions?.toString ? data.regions : spinner}
          </CounterLink>
        </div>
        <div style={{textAlign: 'center'}}>
          <Box variant="awsui-key-label">Stacks</Box>
          <CounterLink link="/stacks">
            {data?.stacks?.toString ? data.stacks : spinner}
          </CounterLink>
        </div>
        <div style={{textAlign: 'center'}}>
          <Box variant="awsui-key-label">Databases</Box>
          <CounterLink link="/databases">
            {data?.dbhosts?.toString ? data.dbhosts : spinner}
          </CounterLink>
        </div>
        <div style={{textAlign: 'center'}}>
          <Box variant="awsui-key-label">Load Balancers</Box>
          <CounterLink link="/loadbalancers">
            {data?.lbhosts?.toString ? data.lbhosts : spinner}
          </CounterLink>
        </div>
        <div style={{textAlign: 'center'}}>
          <Box variant="awsui-key-label">Storage</Box>
          <CounterLink link="/storage">
            {data?.dataroots?.toString ? data.dataroots : spinner}
          </CounterLink>
        </div>
        <div style={{textAlign: 'center'}}>
          <Box variant="awsui-key-label">S3 Buckets</Box>
          <CounterLink link="/s3buckets">
            {data?.s3buckets?.toString ? data.s3buckets : spinner}
          </CounterLink>
        </div>
        <div style={{textAlign: 'center'}}>
          <Box variant="awsui-key-label">Cache Hosts</Box>
          <CounterLink link="/cachehosts">
            {data?.cachehosts?.toString ? data.cachehosts : spinner}
          </CounterLink>
        </div>
      </ColumnLayout>
    </Container>
  );
}
