import { useEffect, useState } from 'react';

import {
    Button,
    SpaceBetween,
    Modal,
    Box,
    Alert,
    ColumnLayout,
    FormField,
    Input,
  } from '@cloudscape-design/components';

export default function TemplateModal({ sites, visible, onDiscard, onSubmit, action }) {
  const templateConsentText = 'confirm';

  const [templateInputText, setTemplateInputText] = useState('');
  useEffect(() => {
    setTemplateInputText('');
  }, [visible]);

  const handleTemplateSubmit = event => {
    event.preventDefault();
    if (inputMatchesConsentText) {
      onSubmit();
    }
  };

  const inputMatchesConsentText = templateInputText.toLowerCase() === templateConsentText;

  const isMultiple = sites.length > 1;
  return (
    <Modal
      visible={visible}
      onDismiss={onDiscard}
      // header={isMultiple ? "Template sites" : "Template site"}
      header={isMultiple ? `${action} sites` : `${action} site`}
      closeAriaLabel="Close dialog"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onDiscard}>Cancel</Button>
            <Button variant="primary" onClick={onSubmit} disabled={!inputMatchesConsentText}>{action}</Button>
          </SpaceBetween>
        </Box>
      }
    >
      {sites.length > 0 && (
        <SpaceBetween size="m">
          {isMultiple ? (
            <Box variant="span">
              {action}{' '}
              <Box variant="span" fontWeight="bold">
                {sites.length} sites
              </Box> ?
            </Box>
          ) : (
            <Box variant="span">
              {action} site{' '}
              <Box variant="span" fontWeight="bold">
                {sites[0].name}
              </Box> ?
            </Box>
          )}

          <Alert type="warning">
            Proceeding with this action will {action} sites(s) with all content and can impact related resources.{' '}
          </Alert>

          <Box>To avoid accidental actions we ask you to provide additional written consent.</Box>

          <ColumnLayout columns={2}>
            <form onSubmit={handleTemplateSubmit}>
              <FormField label={`Type "${templateConsentText}" to agree.`}>
                <Input
                  placeholder={templateConsentText}
                  onChange={event => setTemplateInputText(event.detail.value)}
                  value={templateInputText}
                  ariaRequired={true}
                  ariaLabelledby="template-confirmation-label"
                />
              </FormField>
            </form>
          </ColumnLayout>
        </SpaceBetween>
      )}
    </Modal>
  );
}
