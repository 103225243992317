// import React from 'react';
import { TableContent } from './table';
import { SpaceBetween, Button } from '@cloudscape-design/components';

const tableResource = "Plugins";

const columnDefinitions = navigate => [
  // { id: "id", header: "ID", cell: (e) => e.id, sortingField: "id" },
  // { id: "wwwroot", header: "URL", cell: (e) => e.wwwroot, sortingField: "url" },
  { id: "plugin", header: "Plugin", cell: (e) => e.plugin, sortingField: "plugin" },
  { id: "version", header: "Version", cell: (e) => e.version, sortingField: "version" },
  { id: "deleted", header: "Deleted", cell: (e) => e.deleted !== null ? e.deleted : "-", sortingField: "deleted" },
  { id: "standard", header: "Standard", cell: (e) => e.standard ? "Yes" : "No", sortingField: "standard" },
  { id: "missing", header: "Missing", cell: (e) => e.missing ? "Yes" : "No", sortingField: "missing" },
  { id: "instances", header: "Instances", cell: (e) => e.instances !== null ? e.instances : "-", sortingField: "instances" },
]

const visibleContentItems = [
  // { id: "id", label: "ID" },
  // { id: "wwwroot", label: "URL" },
  { id: "plugin", label: "Plugin", editable: false },
  { id: "version", label: "Version" },
  { id: "deleted", label: "Deleted" },
  { id: "standard", label: "Standard" },
  { id: "missing", label: "Missing" },
  { id: "instances", label: "Instances" },
]

const visibleContent = [
  // 'wwwroot',
  'plugin',
  'version',
  'deleted',
  'standard',
  'missing',
  'instances',
]

const buttons = () => {
  return null;
}

const tableButtons = (props) => {
  return (
    <SpaceBetween size="xs" direction="horizontal">
      <Button
        loading={props.loading}
        iconName="refresh"
        onClick={() => { props.setReloadData(true) }}
      />
    </SpaceBetween>
  )
}

export default function SitePlugins({site}) {
  return (
    <TableContent
    resource={tableResource}
    selectionType="none"
    stickyHeader={true}
    variant="embedded"
    api={{
      url: "stats/plugins",
      method: "post",
      payload: {
        wwwroot: site,
      },
    }}
    columnDefinitions={columnDefinitions}
    visibleContent={visibleContent}
    visibleContentItems={visibleContentItems}
    buttons={tableButtons}
    flashbar={null}
    filterType="properties"
    />
  )
}
