import { useEffect, useState } from "react";
import { axiosGet, axiosPost } from "./common";
import { useNavigate } from "react-router-dom";
import ShowNotifications from "./notifications";
import {
  SpaceBetween,
  Header,
  Wizard,
  Button,
  Box,
  FormField,
  Input,
  Select,
  Container
} from '@cloudscape-design/components';

function getRegions(props) {
  if ( props.region.status !== "pending" ) { return; }
  props.setRegion({ ...props.region, status: "loading" });
  axiosGet("regions")
    .then((response) => {
      if (response.data.error !== false) {
        console.log(response.data.error);
      } else {
        var options = response.data.result.map(e => { return { label: e.name, value: e.id } });
        var update = { options: options, status: "finished" }
        if (options.length == 1) {
          update.selection = options[0];
        } else if (options.length > 1) {
          update.selection = { label: "Select a region", value: null };
        } else {
          update.selection = { label: "No regions available", value: null };
        }
        props.setRegion({ ...props.region, ...update, status: "finished" });
      }
    });
}

function getStacks(props) {
  if (props.stack.status !== "pending") { return; }
  if (props.formData.region.selection?.value == null) {
    return;
  }
  props.setStack({ ...props.stack, status: "loading" });
  axiosGet("stacks?regionid="
    + props.formData.region.selection.value)
    .then((response) => {
      if (response.data.error !== false) {
        console.log(response.data.error);
      } else {
        var options = response.data.result.map(e => { return { label: e.name, value: e.id } });
        var update = { options: options, status: "finished" }
        if (options.length == 1) {
          update.selection = options[0]
        } else if (options.length > 1) {
          update.selection = { label: "Select a stack", value: null };
        } else {
          update.selection = { label: "No stacks available - try another region", value: null };
        }
        props.setStack({ ...props.stack, ...update, status: "finished" });
      }
    });
}

function getS3buckets(props) {
  if (props.formData.region.selection?.value == null) { return; }
  props.setS3bucket({ ...props.s3bucket, status: "loading" });
  axiosGet("s3buckets?regionid=" + props.formData.region.selection.value)
    .then((response) => {
      if (response.data.error !== false) {
        console.log(response.data.error);
      } else {
        var options = response.data.result.map(e => { return { label: e.name, value: e.id } });
        var update = { options: options, status: "finished" }
        if (options.length == 1) {
          update.selection = options[0]
        } else if (options.length > 1) {
          update.selection = { label: "Select a bucket", value: null };
        } else {
          update.selection = { label: "No buckets available - try another region", value: null };
        }
        props.setS3bucket({ ...props.s3bucket, ...update, status: "finished" });
      }
    });
}

function getVersions(props) {
  if (props.formData.product.selection?.value == null
    || props.formData.stack.selection?.value == null) { return; }
  props.setVersion({ ...props.version, status: "loading" });
  axiosGet("versions?"
    + "productid=" + props.formData.product.selection.value
    + "&stackids=" + props.formData.stack.selection.value
  )
    .then((response) => {
      if (response.data.error !== false) {
        console.log(response.data.error);
      } else {
        var options = response.data.result.map(e => { return { label: e.name, value: e.id } });
        var update = { options: options, status: "finished" }
        if (options.length == 1) {
          update.selection = options[0]
        } else if (options.length > 1) {
          update.selection = { label: "Select a version", value: null };
        } else {
          update.selection = { label: "No versions available - try another stack", value: null };
        }
        props.setVersion({ ...props.version, ...update, status: "finished" });
      }
    });
}

function getLbhosts(props) {
  if (props.formData.stack.selection?.value == null) { return; }
  props.setLbhost({ ...props.lbhost, status: "loading" });
  axiosGet("lbhosts?stackid=" + props.formData.stack.selection.value)
    .then((response) => {
      if (response.data.error !== false) {
        console.log(response.data.error);
      } else {
        var options = response.data.result.map(e => { return { label: e.name, value: e.id } });
        var update = { options: options, status: "finished" }
        if (options.length == 1) {
          update.selection = options[0]
        } else if (options.length > 1) {
          update.selection = { label: "Select a load balancer", value: null };
        } else {
          update.selection = { label: "No load balancers available - try another stack", value: null };
        }
        props.setLbhost({ ...props.lbhost, ...update, status: "finished" });
      }
    });
}

function getCachehosts(props) {
  if (props.formData.stack.selection?.value == null) { return; }
  props.setCachehost({ ...props.cachehost, status: "loading" });
  axiosGet("cachehosts?stackid=" + props.formData.stack.selection.value)
    .then((response) => {
      if (response.data.error !== false) {
        console.log(response.data.error);
      } else {
        var options = response.data.result.map(e => { return { label: e.name, value: e.id } });
        var update = { options: options, status: "finished" }
        if (options.length == 1) {
          update.selection = options[0]
        } else if (options.length > 1) {
          update.selection = { label: "Select a cache store", value: null };
        } else {
          update.selection = { label: "No cache stores available - try another stack", value: null };
        }
        props.setCachehost({ ...props.cachehost, ...update, status: "finished" });
      }
    });
}

function getDbhosts(props) {
  if (props.formData.stack.selection?.value == null) { return; }
  props.setDbhost({ ...props.dbhost, status: "loading" });
  axiosGet("dbhosts?stackid=" + props.formData.stack.selection.value)
    .then((response) => {
      if (response.data.error !== false) {
        console.log(response.data.error);
      } else {
        var options = response.data.result.map(e => { return { label: e.name, value: e.id } });
        var update = { options: options, status: "finished" }
        if (options.length == 1) {
          update.selection = options[0]
        } else if (options.length > 1) {
          update.selection = { label: "Select a database", value: null };
        } else {
          update.selection = { label: "No databases available - try another stack", value: null };
        }
        props.setDbhost({ ...props.dbhost, ...update, status: "finished" });
      }
    });
}

function getDataroots(props) {
  if (props.formData.stack.selection?.value == null) { return; }
  props.setDataroot({ ...props.dataroot, status: "loading" });
  axiosGet("dataroots?stackid=" + props.formData.stack.selection.value)
    .then((response) => {
      if (response.data.error !== false) {
        console.log(response.data.error);
      } else {
        var options = response.data.result.map(e => { return { label: e.name, value: e.id } });
        var update = { options: options, status: "finished" }
        if (options.length == 1) {
          update.selection = options[0]
        } else if (options.length > 1) {
          update.selection = { label: "Select a dataroot store", value: null };
        } else {
          update.selection = { label: "No dataroot stores available - try another stack", value: null };
        }
        props.setDataroot({ ...props.dataroot, ...update, status: "finished" });
      }
    });
}

function getProducts(props) {
  props.setProduct({ ...props.product, status: "loading" });
  axiosGet("products")
    .then((response) => {
      if (response.data.error !== false) {
        console.log(response.data.error);
      } else {
        var options = response.data.result.map(e => { return { label: e.name, value: e.id } });
        var update = { options: options, status: "finished" }
        if (options.length == 1) {
          update.selection = options[0]
        } else if (options.length > 1) {
          update.selection = { label: "Select a stack", value: null };
        } else {
          update.selection = { label: "No stacks available - try another region", value: null };
        }
        props.setProduct({ ...props.product, ...update, status: "finished" });
      }
    });
}

//single-field validaton to run during their onBlur events
function validateField(props) {
  var errors = false;
  var newErrorText = {}

  switch (props.fieldName) {
    case 'url':
      errors = false;
      newErrorText.url = "";
      if (!props.value.endsWith('.' + process.env.REACT_APP_SITES_DOMAIN)) {
        newErrorText.url = "not a valid url. url should end with '." + process.env.REACT_APP_SITES_DOMAIN + "'";
        errors = true;
      }
      if (props.value.startsWith('http://') || props.value.startsWith('https://')) {
        newErrorText.url = "url should not start with 'http://' or 'https://'";
        errors = true;
      }
      break;
    default:
      break;
  }

  props.setFormErrors({
    ...props.formErrors,
    ...newErrorText
  });
}

function validate(props) {
  var errors = false;
  var newErrorText = {}

  switch (props.e.detail.requestedStepIndex) {
    case 1:
      if (!props.formData.url.endsWith('.' + process.env.REACT_APP_SITES_DOMAIN)) {
        errors = true;
        newErrorText.url = "not a valid url. url should end with 'ethinkdev.net'";
      } else {
        newErrorText.url = "";
      }

      if (props.formData.region.selection?.value == null) {
        errors = true;
        newErrorText.region = "select a region";
      } else {
        newErrorText.region = "";
      }

      if (props.formData.product.selection?.value == null) {
        errors = true;
        newErrorText.product = "select a product";
      } else {
        newErrorText.product = "";
      }

      break;
    case 2:
      if (props.formData.stack.selection?.value == null) {
        errors = true;
        newErrorText.stack = "select a stack";
      } else {
        newErrorText.stack = "";
      }

      if (props.formData.s3bucket.selection?.value == null) {
        errors = true;
        newErrorText.s3bucket = "select an s3 bucket";
      } else {
        newErrorText.s3bucket = "";
      }

      break;
    case 3:
      if (props.formData.version.selection?.value == null) {
        errors = true;
        newErrorText.version = "select a version";
      } else {
        newErrorText.version = "";
      }

      if (props.formData.lbhost.selection?.value == null) {
        errors = true;
        newErrorText.lbhost = "select a load balancer";
      } else {
        newErrorText.lbhost = "";
      }

      if (props.formData.cachehost.selection?.value == null) {
        errors = true;
        newErrorText.cachehost = "select a cache store";
      } else {
        newErrorText.cachehost = "";
      }

      if (props.formData.dbhost.selection?.value == null) {
        errors = true;
        newErrorText.dbhost = "select a database host";
      } else {
        newErrorText.dbhost = "";
      }

      if (props.formData.dataroot.selection?.value == null) {
        errors = true;
        newErrorText.dataroot = "select a dataroot";
      } else {
        newErrorText.dataroot = "";
      }

      break;
    default:
      break;
  }

  props.setFormErrors({
    ...props.formErrors,
    ...newErrorText
  });

  if (errors) {
    if (props.formData.region.selection?.value == null) {
      props.setActiveStepIndex(0);
    } else if (props.formData.stack.selection?.value == null) {
      props.setActiveStepIndex(1);
    } else if (props.e.detail.requestedStepIndex == 3) {
      props.setActiveStepIndex(2);
    } else {
      props.setActiveStepIndex(props.activeStepIndex);
    }
    return;
  }

  props.setActiveStepIndex(props.e.detail.requestedStepIndex);
}

export function createSite(formData, flashbar, navigate, setIsLoadingNextStep) {
  setIsLoadingNextStep(true);
  var data = {
    name: formData.url,
    // regionid: formData.region.selection.value,
    // productid: formData.product.selection.value,
    currentversionid: formData.version.selection?.value,
    stackid: formData.stack.selection?.value,
    s3bucketid: formData.s3bucket.selection?.value,
    lbhostid: formData.lbhost.selection?.value,
    cachehostid: formData.cachehost.selection?.value,
    dbhostid: formData.dbhost.selection?.value,
    datarootid: formData.dataroot.selection?.value
  }
  // console.log("Will create site: ", data);
  axiosPost("sites", data)
  .then((response) => {
    if (response.data.error !== false) {
      // console.log(response.data.error);
      setIsLoadingNextStep(false);
      ShowNotifications({
        child: "sites",
        flashbar: flashbar,
        header: `Error creating new site:`,
        message: response?.data?.result || "Unknown error",
        type: "error"
      });
    } else {
      ShowNotifications({
        child: "sites",
        flashbar: flashbar,
        header: `Created new site:`,
        message: data.name
      });
      setIsLoadingNextStep(false);
      navigate("/sites/" + response.data.result[0].id);
    }
  })
  .catch((error) => {
    // console.log(error);
    setIsLoadingNextStep(false);
    ShowNotifications({
      child: "sites",
      flashbar: flashbar,
      header: `Error creating new site:`,
      message: error.response?.data?.result || error.message,
      type: "error"
    });
  });
}

export function NewSiteWizard(props) {
  const navigate = useNavigate();
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [isLoadingNextStep, setIsLoadingNextStep] = useState(false);

  var defaultSelectState = {
    options: [],
    status: "pending",
    selection: null,
  }

  const [url, setUrl] = useState("");
  const [region, setRegion] = useState(defaultSelectState);
  const [stack, setStack] = useState(defaultSelectState);
  const [s3bucket, setS3bucket] = useState(defaultSelectState);
  const [dbhost, setDbhost] = useState(defaultSelectState);
  const [lbhost, setLbhost] = useState(defaultSelectState);
  const [cachehost, setCachehost] = useState(defaultSelectState);
  const [dataroot, setDataroot] = useState(defaultSelectState);
  const [version, setVersion] = useState(defaultSelectState);
  const [product, setProduct] = useState(defaultSelectState);

  const [formData, setFormData] = useState({
    url: "",
    region: defaultSelectState,
    stack: defaultSelectState,
    s3bucket: defaultSelectState,
    dbhost: defaultSelectState,
    lbhost: defaultSelectState,
    cachehost: defaultSelectState,
    dataroot: defaultSelectState,
    version: defaultSelectState,
    product: defaultSelectState
  });

  const [formErrors, setFormErrors] = useState({
    url: "",
    region: "",
    stack: "",
    s3bucket: "",
    dbhost: "",
    lbhost: "",
    cachehost: "",
    dataroot: "",
    version: "",
    product: ""
  });

  const formFields = {
    url: <FormField
      label="URL"
      description="Specify a URL that will be used to identify and access the site. Must be a sub-domain of an OpenLMS hosting domain."
      constraintText="Desired site URL, without https:// protocol prefix"
      errorText={formErrors.url}
    >
      <Input
        value={url}
        onChange={({ detail }) => {
          setUrl(detail.value);
          validateField({ fieldName: 'url', value: detail.value, formErrors, setFormErrors });
        }}
        type="url"
        inputMode="url"
        defaultValue=""
        disableBrowserAutocorrect
      /></FormField>,
    region: <FormField
      label="Region"
      description="Select an AWS region where site resources will reside. Region selection determines availability of most other subsequent selections."
      errorText={formErrors.region}
    >
      <Select
        placeholder="Select a region"
        selectedOption={region.selection}
        onChange={(e) => {
          setRegion({ ...region, selection: e.detail.selectedOption });
        }}
        options={region.options}
        statusType={region.status}
        loadingText="Loading regions"
        empty="No regions available"
        errorText="Error loading regions"
        recoveryText="Retry"
        onLoadItems={() => {getRegions({ region, setRegion }); }}
      /></FormField>,
    product: <FormField
      label="Product"
      description="Select the OpenLMS product that you would like this site to host."
      errorText={formErrors.product}
    >
      <Select
        placeholder="Select a product"
        selectedOption={product.selection}
        onChange={(e) => { setProduct({ ...product, selection: e.detail.selectedOption }); }}
        options={product.options}
        statusType={product.status}
        loadingText="Loading products"
        empty="No products available"
        errorText="Error loading products"
        recoveryText="Retry"
        onLoadItems={() => { getProducts({ product, setProduct }); }}
      /></FormField>,
    stack: <FormField
      label="Stack"
      description="A stack is a collection of related cloud resources that live in a specific region"
      errorText={formErrors.stack}
    >
      <Select
        placeholder="Select a stack"
        selectedOption={stack.selection}
        onChange={(e) => {
          setStack({ ...stack, selection: e.detail.selectedOption });
        }}
        options={stack.options}
        statusType={stack.status}
        loadingText="Loading stacks"
        empty="No stacks available"
        errorText="Error loading stacks"
        recoveryText="Retry"
        onLoadItems={() => { getStacks({ stack, setStack, formData }); }}
      /></FormField>,
    s3bucket: <FormField
      label="S3 Bucket"
      description="S3 is the AWS object storage solution, an S3 bucket is a collection of objects (files) that must reside in the same region as other stack resources"
      errorText={formErrors.s3bucket}
    >
      <Select
        placeholder="Select an S3 bucket"
        selectedOption={s3bucket.selection}
        onChange={(e) => setS3bucket({ ...s3bucket, selection: e.detail.selectedOption })}
        options={s3bucket.options}
        statusType={s3bucket.status}
        loadingText="Loading S3 buckets"
        empty="No S3 buckets available"
        errorText="Error loading S3 buckets"
        recoveryText="Retry"
        onLoadItems={() => { getS3buckets({ s3bucket, setS3bucket, formData }); }}
      /></FormField>,
    lbhost: <FormField
      label="Load Balancer"
      description="The load balancer is where DNS will route web requests for your site"
      errorText={formErrors.lbhost}
    >
      <Select
        placeholder="Select a load balancer"
        selectedOption={lbhost.selection}
        onChange={(e) => setLbhost({ ...lbhost, selection: e.detail.selectedOption })}
        options={lbhost.options}
        statusType={lbhost.status}
        loadingText="Loading load balancers"
        empty="No load balancers available"
        errorText="Error loading load balancers"
        recoveryText="Retry"
        onLoadItems={() => { getLbhosts({ lbhost, setLbhost, formData }); }}
      /></FormField>,
    cachehost: <FormField
      label="Cache Store"
      description="The cache store is where application (MUC) cache will be stored"
      errorText={formErrors.cachehost}
    >
      <Select
        placeholder="Select a cache store"
        selectedOption={cachehost.selection}
        onChange={(e) => setCachehost({ ...cachehost, selection: e.detail.selectedOption })}
        options={cachehost.options}
        statusType={cachehost.status}
        loadingText="Loading cache stores"
        empty="No cache stores available"
        errorText="Error loading cache stores"
        recoveryText="Retry"
        onLoadItems={() => { getCachehosts({ cachehost, setCachehost, formData }); }}
      /></FormField>,
    dbhost: <FormField
      label="Database Host"
      description="The database host is the cloud instance where your site's database will reside"
      errorText={formErrors.dbhost}
    >
      <Select
        placeholder="Select a database host"
        selectedOption={dbhost.selection}
        onChange={(e) => setDbhost({ ...dbhost, selection: e.detail.selectedOption })}
        options={dbhost.options}
        statusType={dbhost.status}
        loadingText="Loading database hosts"
        empty="No database hosts available"
        errorText="Error loading database hosts"
        recoveryText="Retry"
        onLoadItems={() => { getDbhosts({ dbhost, setDbhost, formData }); }}
      /></FormField>,
    dataroot: <FormField
      label="Dataroot"
      description="The dataroot represents shared on-disk storage for your site. Typically, this is where application data is saved."
      errorText={formErrors.dataroot}
    >
      <Select
        placeholder="Select a dataroot"
        selectedOption={dataroot.selection}
        onChange={(e) => setDataroot({ ...dataroot, selection: e.detail.selectedOption })}
        options={dataroot.options}
        statusType={dataroot.status}
        loadingText="Loading dataroots"
        empty="No dataroots available"
        errorText="Error loading dataroots"
        recoveryText="Retry"
        onLoadItems={() => { getDataroots({ dataroot, setDataroot, formData }); }}
      /></FormField>,
    version: <FormField
      label="Version"
      description="Every product can have multiple different code versons available."
      errorText={formErrors.version}
    >
      <Select
        placeholder="Select a product version"
        selectedOption={version.selection}
        onChange={(e) => {
          setVersion({ ...version, selection: e.detail.selectedOption });
        }}
        options={version.options}
        statusType={version.status}
        loadingText="Loading product versions"
        empty="No product versions available"
        errorText="Error loading product versions"
        recoveryText="Retry"
        onLoadItems={() => { getVersions({ version, setVersion, formData }); }}
      /></FormField>
  };

  const steps = [
    {
      title: "Basic site information",
      description: "Let's start with some basic information about the new site.",
      content: (
        <Container>
          <SpaceBetween direction="vertical" size="l">
            {formFields.url}
            {formFields.region}
            {formFields.product}
            {/* {formFields.version} */}
          </SpaceBetween>
        </Container>
      )
    },
    {
      title: "Region-dependant resources",
      description: "The selections available below are determined by the region you picked",
      content: (
        <Container>
          <SpaceBetween direction="vertical" size="l">
            {formFields.stack}
            {formFields.s3bucket}
          </SpaceBetween>
        </Container>
      )
    },
    {
      title: "Stack-dependant resources",
      description: "The selections available below are determined by the stack you picked",
      content: (
        <Container>
          <SpaceBetween direction="vertical" size="l">
            {formFields.version}
            {formFields.lbhost}
            {formFields.cachehost}
            {formFields.dbhost}
            {formFields.dataroot}
          </SpaceBetween>
        </Container>
      )
    },
    {
      title: "Review selections",
      content: (
        <SpaceBetween size="m">
          <Header
            variant="h3"
            actions={
              <Button
                onClick={() => setActiveStepIndex(0)}
              >
                Edit
              </Button>
            }
          >
          </Header>

          <Container
            header={
              <Header variant="h2">
                Basic selections
              </Header>
            }
          >
            <SpaceBetween size="l">
              <div>
                <Box variant="awsui-key-label">URL</Box>
                <div>{formData.url}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Region</Box>
                <div>{formData.region.selection?.label}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Product</Box>
                <div>{formData.product.selection?.label}</div>
              </div>
            </SpaceBetween>
          </Container>

          <Container
            header={
              <Header variant="h2">
                Region-dependent selections
              </Header>
            }
          >
            <SpaceBetween size="l">
              <div>
                <Box variant="awsui-key-label">Stack</Box>
                <div>{formData.stack.selection?.label}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">S3 Bucket</Box>
                <div>{formData.s3bucket.selection?.label}</div>
              </div>
            </SpaceBetween>
          </Container>

          <Container
            header={
              <Header variant="h2">
                Stack-dependent selections
              </Header>
            }
          >
            <SpaceBetween size="l">
              <div>
                <Box variant="awsui-key-label">Version</Box>
                <div>{formData.version.selection?.label}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Load Balancer Host</Box>
                <div>{formData.lbhost.selection?.label}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Cache Store</Box>
                <div>{formData.cachehost.selection?.label}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Database Host</Box>
                <div>{formData.dbhost.selection?.label}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Dataroot</Box>
                <div>{formData.dataroot.selection?.label}</div>
              </div>
            </SpaceBetween>
          </Container>
        </SpaceBetween>
      )
    }
  ];

  //clear stack,s3bucket on region change
  useEffect(() => {
    setStack({ ...stack, selection: null, status: "pending", options: [] });
    setS3bucket({ ...s3bucket, selection: null, status: "pending", options: [] });
    setFormErrors({ ...formErrors, stack: "", s3bucket: "" });
  }, [ region ]);

  //clear version,lbhost,cachehost,dbhost,dataroot on stack change
  useEffect(() => {
    setVersion({ ...version, selection: null, status: "pending", options: [] });
    setDbhost({ ...dbhost, selection: null, status: "pending", options: [] });
    setCachehost({ ...cachehost, selection: null, status: "pending", options: [] });
    setLbhost({ ...lbhost, selection: null, status: "pending", options: [] });
    setDataroot({ ...dataroot, selection: null, status: "pending", options: [] });
    setFormErrors({ ...formErrors, version: "", dbhost: "", cachehost: "", lbhost: "", dataroot: "" });
  }, [stack]);

  //trigger update formData on change of anything else
  useEffect(() => {
    setFormData({
      url: url,
      region: region,
      stack: stack,
      s3bucket: s3bucket,
      dbhost: dbhost,
      lbhost: lbhost,
      cachehost: cachehost,
      dataroot: dataroot,
      version: version,
      product: product
    })
  }, [url, region, stack, s3bucket, dbhost, lbhost, dataroot, version, product]);

  return (
    <Wizard
      i18nStrings={{
        stepNumberLabel: stepNumber => `Step ${stepNumber}`,
        collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
        skipToButtonLabel: (step, stepNumber) => `Skip to ${step.title}`,
        cancelButton: "Cancel",
        previousButton: "Previous",
        nextButton: "Next",
        submitButton: "Create Site",
        optional: "optional"
      }}
      onNavigate={(e) => validate({ e, formData, formErrors, setFormErrors, activeStepIndex, setActiveStepIndex })}
      onSubmit={() => createSite(formData, props.flashbar, navigate, setIsLoadingNextStep)}
      onCancel={() => navigate("/sites")}
      activeStepIndex={activeStepIndex}
      isLoadingNextStep={isLoadingNextStep}
      steps={steps}
    />
  );
}
