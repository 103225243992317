import { axiosPost } from "./common";
import ShowNotifications from "./notifications";

export default function sitelogin(site, setLoading, flashbar) {
  setLoading = typeof setLoading === "function" ? setLoading : () => {};
  setLoading(true);
  axiosPost("sites/auth", { wwwroot: site })
    .then((response) => {
      if (response.data.error !== false) {
        console.log(response.data.error);
        setLoading(false);
        ShowNotifications({
          child: "sites",
          flashbar: flashbar,
          header: `Error from auth api:`,
          message: response?.data?.result || "Unknown error",
          type: "error",
        });
      } else {
        // after 1 second, redirect to the site
        // this gives dynamo time to update the site record.
        setTimeout(() => {          
          var win = window.open(response.data.result.url, "_blank");
          win.focus();
          setLoading(false);
        }, 1000);
      }
    })
    .catch((error) => {
      console.log(error);
      setLoading(false);
      ShowNotifications({
        child: "sites",
        flashbar: flashbar,
        header: `Error from auth api:`,
        message: error.response?.data?.result || error.message,
        type: "error",
      });
    });
}
