import { useEffect, useState } from 'react';
import {
  ContentLayout,
  Button,
  ButtonDropdown,
  SpaceBetween,
} from '@cloudscape-design/components';

import { RegionsHeader } from "../components/header";
import { loggedIn, capitalizeFirst } from "../components/common";
import { TableContent } from "../components/table";
import DeleteModal from "../components/delete-modal";
import { deleteResource } from '../components/common';

const tableResource = "regions";

const columnDefinitions = navigate => [
  { id: "id", header: "ID", cell: (e) => e.id, sortingField: "id" },
  { id: "name", header: "Name", cell: (e) => e.name, sortingField: "name" },
  { id: "account", header: "AWS Account", cell: (e) => e.account, sortingField: "account" },
]

const visibleContent = [
  "name",
  "account",
]

const visibleContentItems = [
  { id: "id", label: "ID" },
  { id: "name", label: "Name", editable: false },
  { id: "account", label: "AWS Account", editable: false },
]

const tableButtons = (props) => {
  /*
    Props available are:
    selectedItems: array of selected items
    loading: boolean - true if table is loading
    setReloadData: function to reload data. use SetReloadData(true)
    navigate: function to navigate to a page. passed to useNavigate()
    flashbar: functions to set flashbar items.
  */
    const [reload, setReload] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const onDeleteInit = () => setShowDeleteModal(true);
    const onDeleteDiscard = () => setShowDeleteModal(false);
    const onDeleteConfirm = () => {
      const deleted = props.selectedItems;
      props.setSelectedItems([]);
      setShowDeleteModal(false);
      deleteResource(
        deleted[0],
        capitalizeFirst(tableResource.slice(0, -1)),
        tableResource,
        props.flashbar,
        props.setReloadData
      );
    };
  
    useEffect(() => {
      if (reload) {
        props.setReloadData(true);
        // props.flashbar.setFlashbarItems([]);
        setReload(false);
      }
    }, [reload]);
  
  return (
    <SpaceBetween size="xs" direction="horizontal">
      <DeleteModal
        visible={showDeleteModal}
        onDiscard={onDeleteDiscard}
        onDelete={onDeleteConfirm}
        items={props.selectedItems}
        type={tableResource.slice(0, -1)}
      />
      <Button
        loading={props.loading}
        iconName="refresh"
        onClick={() => { props.setReloadData(true) }}
      />
      <ButtonDropdown
        expandToViewport
        disabled={props.selectedItems.length === 0}
        items={[
          // { text: "Edit", id: "edit", disabled: props.selectedItems.length !== 1 },
          { text: "Delete", id: "delete", disabled: (props.selectedItems.length === 0) },
        ]}
        onItemClick={event => {
          if (event.detail.id === "delete") {
            onDeleteInit();
          }
        }}
      >
        Actions
      </ButtonDropdown>
      <Button
        variant="primary"
        onClick={() => {
          props.navigate(`/${tableResource}/new`);
        }}
      >
        Create {capitalizeFirst(tableResource.slice(0, -1))}
      </Button>
    </SpaceBetween>
  );
}

export default function Regions({ state }) {
  if (!loggedIn()) {
    return null;
  }
  useEffect(() => {
    state.activeHref.setHref("/regions");
    state.breadCrumbs.setCrumbs(null);
    state.maxContentWidth.setWidth(Number.MAX_VALUE);
    state.contentType.setType("default");
  }, []);
  return (
    <ContentLayout header={<RegionsHeader/>}>
      <TableContent
        resource={tableResource}
        selectionType="single"
        api={{
          url: "regions",
          method: "get",
          // payload: null,
        }}
        columnDefinitions={columnDefinitions}
        visibleContent={visibleContent}
        visibleContentItems={visibleContentItems}
        buttons={tableButtons}
        flashbar={state.flashBar}
      />
    </ContentLayout>
  );
}
