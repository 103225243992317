import { useEffect, useState } from 'react';

import {
    Button,
    SpaceBetween,
    Modal,
    Box,
    Alert,
    ColumnLayout,
    FormField,
    Input,
  } from '@cloudscape-design/components';

export default function DeleteModal({ items, type, visible, onDiscard, onDelete }) {
  const deleteConsentText = 'confirm';

  const [deleteInputText, setDeleteInputText] = useState('');
  useEffect(() => {
    setDeleteInputText('');
  }, [visible]);

  const handleDeleteSubmit = event => {
    event.preventDefault();
    if (inputMatchesConsentText) {
      onDelete();
    }
  };

  const inputMatchesConsentText = deleteInputText.toLowerCase() === deleteConsentText;

  const isMultiple = items.length > 1;
  return (
    <Modal
      visible={visible}
      onDismiss={onDiscard}
      header={isMultiple ? `Delete ${type}s` : `Delete ${type}`}
      closeAriaLabel="Close dialog"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onDiscard}>Cancel</Button>
            <Button variant="primary" onClick={onDelete} disabled={!inputMatchesConsentText}>Delete</Button>
          </SpaceBetween>
        </Box>
      }
    >
      {items.length > 0 && (
        <SpaceBetween size="m">
          {isMultiple ? (
            <Box variant="span">
              Delete{' '}
              <Box variant="span" fontWeight="bold">
                {items.length} {type}s
              </Box>{' '}
              permanently? This action cannot be undone.
            </Box>
          ) : (
            <Box variant="span">
              Delete {type}{' '}
              <Box variant="span" fontWeight="bold">
                {items[0].name}
              </Box>{' '}
              permanently? This action cannot be undone.
            </Box>
          )}

          <Alert type="warning">
            Proceeding with this action will delete {type}(s) with all content and can impact related resources.{' '}
          </Alert>

          <Box>To avoid accidental deletions we ask you to provide additional written consent.</Box>

          <ColumnLayout columns={2}>
            <form onSubmit={handleDeleteSubmit}>
              <FormField label={`Type "${deleteConsentText}" to agree.`}>
                <Input
                  placeholder={deleteConsentText}
                  autoFocus={true}
                  autoComplete={false}
                  onChange={event => setDeleteInputText(event.detail.value)}
                  value={deleteInputText}
                  ariaRequired={true}
                  ariaLabelledby="delete-confirmation-label"
                />
              </FormField>
            </form>
          </ColumnLayout>
        </SpaceBetween>
      )}
    </Modal>
  );
}
