// import React from 'react';
import { TableContent } from './table';
import { SpaceBetween, Button } from '@cloudscape-design/components';

const tableResource = "events";

const columnDefinitions = navigate => [
  // { id: "id", header: "ID", cell: (e) => e.id, sortingField: "id" },
  { id: "timestamp", header: "Time", cell: (e) => new Date(e.timestamp).toLocaleString('en-US', {"dateStyle": "medium", "timeStyle": "long"}), sortingField: "time" },
  // { id: "siteid", header: "Site ID", cell: (e) => e.siteid, sortingField: "siteid" },
  { id: "site", header: "Site", cell: (e) => e.site, sortingField: "site" },
  { id: "username", header: "Username", cell: (e) => e.username, sortingField: "username" },
  { id: "type", header: "Type", cell: (e) => e.type, sortingField: "type" },
  { id: "event", header: "Event", cell: (e) => {
    if (e.type == "token") {
      const event = JSON.parse(e.event);
      return event.action + ": " + event.log;
    }
    return e.event;
  }, sortingField: "event" },
]

const visibleContent = [
  "timestamp",
  "site",
  "username",
  "type",
  "event",
]

const visibleContentItems = [
  // { id: "id", label: "ID" },
  // { id: "siteid", label: "Site ID" },
  { id: "site", label: "Site", editable: false },
  { id: "username", label: "Username", editable: false },
  { id: "type", label: "Type", editable: false },
  { id: "event", label: "Event", editable: false },
]

const tableButtons = (props) => {
  return (
    <SpaceBetween size="xs" direction="horizontal">
      <Button
        loading={props.loading}
        iconName="refresh"
        onClick={() => { props.setReloadData(true) }}
      />
    </SpaceBetween>
  )
}

export default function SiteEvents({siteId}) {
  return (
    <TableContent
    resource={tableResource}
    selectionType="none"
    stickyHeader={true}
    variant="embedded"
    api={{
      url: "events?siteid=" + siteId + "&pretty=1",
      method: "get",
    }}
    columnDefinitions={columnDefinitions}
    visibleContent={visibleContent}
    visibleContentItems={visibleContentItems}
    buttons={tableButtons}
    flashbar={null}
    filterType="properties"
    />
  )
}
